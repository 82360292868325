import {
  SubmitInput,
  FormProvider,
  SelectInput,
  StandardInput,
  CheckboxInput,
  DayPickerInput,
  TextareaInput,
} from "../../form";
import styles from "../../../styles/partials/forms/scheduleTour-form.scss";
import { useState, useEffect } from "react";
import {
  _classes,
  price_max_options,
  constructString,
  constructLayout,
  layoutsMap,
  convertTime,
} from "../../utilities/helpers";
import { useAppState } from "../../providers";
import axios from "axios";
import moment from "moment";
import APP_CONFIG from "../../config/appConfig";
const cl = _classes(styles);
const baseNestionUrl = APP_CONFIG.nestio.base_url;
function ScheduleTourForm({ id, type }) {
  const {
    byTemplate,
    // openBookTour
  } = useAppState();
  const global = byTemplate("global")[0];
  const buildings = global.fieldgroup4 || [];
  const parsedId = (id && id.toString()) || null;

  const buildings_options =
    buildings &&
    buildings.length > 0 &&
    buildings.map((building) => ({
      label: building.h1,
      value: building.h2,
      key: building.h3,
      nestioId: building.map_link,
    }));

  const keyForUnit =
    buildings.length > 0 && id
      ? buildings?.find((ele) => ele.h2 === parsedId)?.h3
      : null;

  const location =
    buildings.length > 0 && id
      ? buildings?.find((ele) => ele.h2 === parsedId)?.h1
      : null;

  const [leasingOffice, setLeasingOffice] = useState({
    id: "",
    key: "",
    location: "",
  });
  const [selectedDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage_office, setErrorMessage_office] = useState(false);
  const [errorMessage_date] = useState(false);
  const [notAvailable, setNotAvailable] = useState(false);
  const [timeSlot, setTimeSlot] = useState([]);
  const [broker, setBroker] = useState(false);
  const [thirdPartySuccess, setThirdPartySuccess] = useState(false);
  const toggleBroker = () => {
    setBroker(!broker);
  };

  // required data for post
  const [, setStart] = useState("");
  const [timeStart, setTimeStart] = useState("");
  const [is_video_tour, setIs_video_tour] = useState(false);
  const [layoutField, setLayoutField] = useState([]);
  const [platform_of_choice, setPlatform_of_choice] = useState("");

  const isVideoTour = is_video_tour === "Live Video Tour";
  const renderTimeSlotOption = () => {
    let timeOption = [];
    if (timeSlot.length > 0) {
      timeOption = timeSlot.map((time) => ({
        label: convertTime(time.slice(11, 16)),
        value: time,
      }));
      return timeOption;
    } else {
      timeOption = [];
      return timeOption;
    }
  };
  const _buildID = id ? id.toString() : leasingOffice?.id;

  let price_options = [...price_max_options];
  const option4000 = { label: "4,000", value: 4000 };
  const option4995 = { label: "3,995", value: 3995 };
  const isDominoSquare = id === 5225;
  if (isDominoSquare) {
    //Replace the 4000 option with the 3995 option
    price_options.splice(price_options.indexOf(option4000), 1, option4995);
  }

  const handleFetch = async (selectedDate) => {
    setStart(selectedDate);
    selectedDate = moment(selectedDate || new Date()).format("YYYY-MM-DD");

    if (_buildID && _buildID !== "" && selectedDate) {
      setLoading(true);

      try {
        const response = await axios({
          url: `${baseNestionUrl}${_buildID}/available-times/?from_date=${selectedDate}`,
          dataType: "json",
          headers: {
            // Authorization: leasingOffice.key?"Basic " + window.btoa(leasingOffice.key + ":"):"Basic " + window.btoa(keyForUnit + ":"),
            Authorization: keyForUnit
              ? "Basic " + window.btoa(keyForUnit + ":")
              : "Basic " + window.btoa(leasingOffice.key + ":"),
          },
        });

        const hasAvailableTimes = response.data.available_times?.length >= 1;

        const times = response.data.available_times || [];

        if (hasAvailableTimes) {
          setTimeSlot(times);
          setNotAvailable(false);
        } else {
          setTimeSlot([]); // Clear time slots if no available times
          setNotAvailable(true);
        }

        if (response.status === 200 || response.status === 201) {
          setLoading(false);
        }
      } catch (error) {
        console.log(error);

        const availableTimes = error.response.data.available_times || [];

        setNotAvailable(true);
        setTimeSlot(availableTimes);
        // error_maessage(s)::(error.response.data.errors.start?.[0]);
      }
    }
  };

  useEffect(() => {
    handleFetch(selectedDate);
  }, [leasingOffice.id]);

  // useEffect(() => {
  //   console.log("Time Slot Chosen", timeSlot);
  // }, [timeSlot.length]);

  const handleSubmit = (formData) => {
    const data = {
      client: {
        people: [
          {
            first_name: formData.first_name,
            last_name: formData.last_name,
            email: formData.email,
            phone_1: formData.phone,
          },
        ],
        // group: parseInt(_buildID), //IMPORTANT: We don't need this here as we are using the building id in the url
        layout: constructLayout(layoutField, layoutsMap), // example ["studio", "1br"],
        price_ceiling: isNaN(formData.max_price)
          ? ""
          : Number(formData.max_price).toFixed(2),
        move_in_date: moment(formData.move_in_date).format("YYYY-MM-DD"), //"2022-04-30",
        notes:
          formData.notes !== ""
            ? `${formData.notes}.\n brokerage_firm: ${
                formData.brokerage_firm || "NA"
              }.\n layouts: ${constructString(
                layoutField
              )} \n platform_of_choice: ${platform_of_choice || "NA"}`
            : `I would like to schedule a tour.\n brokerage_firm: ${
                formData.brokerage_firm || "NA"
              }.\n layouts: ${constructString(layoutField)}`, //brokerage_firm
        sms_opted_in: "marketing-enabled",
        campaign_info: null,
        is_broker: broker,
        broker: formData.brokerage_firm,
      },
      appointment: {
        start: timeStart,
        expiration_date: null,
        location: location ? location : leasingOffice.location,
        broker_booked: false,
        tour_type: "guided",
        is_video_tour: !isVideoTour ? false : true,
        platform_of_choice: platform_of_choice,
      },
    };

    try {
      axios({
        method: "POST",
        url: baseNestionUrl + _buildID + "/book/",
        dataType: "json",
        data: JSON.stringify(data),
        headers: {
          Authorization: keyForUnit
            ? "Basic " + window.btoa(keyForUnit + ":")
            : "Basic " + window.btoa(leasingOffice.key + ":"),
          "Content-Type": "application/json",
        },
      }).then((response) => {
        // Success🎉
        if (response.status === 200 || response.status === 201) {
          setThirdPartySuccess(true);
          setBroker(false);
        }
      });
    } catch (error) {
      console.log(error);
    }

    setTimeout(() => {
      setLayoutField([]);
      setStart("");
      setTimeStart("");
      setIs_video_tour(false);
      setPlatform_of_choice("");
    }, "5000");
  };
  const layoutToggle = (value) => {
    setLayoutField((state) =>
      state.includes(value)
        ? state.filter((s) => s !== value)
        : [...state, value]
    );
  };

  return (
    <div className={cl("schedule_tour_form")} style={{ background: "white" }}>
      <FormProvider
        additionalData={{
          layout: layoutField,
        }}
        onSubmit={handleSubmit}
        thankyouMessage={() => (
          <div
            id="schedule-tour-form-thank-you"
            className={cl(["thank_you_message", "thank_you_message_tracker"])}
          >
            Thank you! A Leasing Associate will be in touch with you shortly.
          </div>
        )}
        thirdPartySuccess={thirdPartySuccess}
        type={"schedule_tour_form"}
      >
        <div className={cl("full_width")}>
          {!id && type !== "unit" && (
            <SelectInput
              name="location"
              options={buildings_options}
              className={cl("schedule_tour_select")}
              placeholder={"Select Neighborhood/ Building of Interest"}
              rules={{
                required: "Please select a leasing office to schedule a tour",
              }}
              onSelect={(e) => {
                setLeasingOffice({
                  id: e.value,
                  key: e.key,
                  location: e.label,
                });
                handleFetch(e.key);
                setErrorMessage_office(false);
              }}
            />
          )}
          {errorMessage_office && (
            <div className={cl("errorMessage")}>
              Please select a leasing office to schedule a tour.
            </div>
          )}
        </div>
        <div className={cl("row_wrapper")}>
          <div className={cl("left_wrapper")} id="schedule_tour_left_wrapper">
            <DayPickerInput
              key={leasingOffice.id}
              placeholder={
                type !== "unit" ? "Choose Appointment Date" : "Tour Date"
              }
              name="selectedBookingDate"
              onDayChange={(value) => handleFetch(value)}
              rules={{ required: "Please select a date" }}
            />
            {errorMessage_date && (
              <div className={cl("errorMessage")}>
                Please select a date to schedule a tour.
              </div>
            )}
            {notAvailable && (
              <div className={cl("errorMessage")}>
                No availability on the selected date, please select another.
              </div>
            )}
          </div>
          <div className={cl("right_wrapper")}>
            {timeSlot.length > 0 && (
              <SelectInput
                key={timeSlot.length + leasingOffice.id}
                name="start"
                options={renderTimeSlotOption()}
                className={cl("schedule_tour_select")}
                placeholder={
                  type !== "unit" ? "Choose Appointment Time" : "Tour Time"
                }
                rules={{ required: "Time is required" }}
                onSelect={(e) => setTimeStart(e.value)}
              />
            )}
            {timeSlot.length === 0 && (
              <SelectInput
                key={timeSlot.length + leasingOffice.id}
                name="start"
                options={[]}
                className={cl("schedule_tour_select")}
                placeholder={
                  type !== "unit" ? "Choose Appointment Time" : "Tour Time"
                }
                rules={{ required: "Time is required" }}
              />
            )}
            {loading && !notAvailable && (
              <div className={cl("loading")}>Loading Time Options</div>
            )}
          </div>
        </div>

        <div className={cl("row_wrapper")}>
          <div className={cl("left_wrapper")}>
            <SelectInput
              name="is_video_tour"
              options={[
                { label: "In Person", value: "In Person" },
                { label: "Live Video Tour", value: "Live Video Tour" },
              ]}
              className={cl("schedule_tour_select")}
              placeholder={"Tour Type"}
              rules={{ required: "Select is required" }}
              onSelect={(e) => setIs_video_tour(e.value)}
            />
          </div>

          <div className={cl("right_wrapper")}>
            <SelectInput
              name="platform_of_choice"
              options={
                isVideoTour
                  ? [
                      { label: "Zoom", value: "zoom" },
                      { label: "FaceTime", value: "facetime" },
                    ]
                  : []
              }
              className={cl("schedule_tour_select")}
              placeholder={"Platform Type"}
              rules={{ required: isVideoTour && "Select is required" }}
              onSelect={(e) => setPlatform_of_choice(e.value)}
            />
          </div>
        </div>

        <div className={cl("row_wrapper")}>
          <div className={cl("left_wrapper")}>
            <StandardInput
              // disabled={!openBookTour}
              className={cl("schedule_tour_standard")}
              label={""}
              name="first_name"
              placeholder="First Name"
              rules={{ required: "Please enter your first name." }}
            />
          </div>

          <div className={cl("right_wrapper")}>
            <StandardInput
              // disabled={!openBookTour}
              label={""}
              className={cl("schedule_tour_standard")}
              name="last_name"
              placeholder="Last Name"
              rules={{ required: "Please enter your last name." }}
            />
          </div>
        </div>
        {!id && type !== "unit" ? (
          <div className={cl("row_wrapper")}>
            <div className={cl("left_wrapper")}>
              <StandardInput
                // disabled={!openBookTour}
                label={""}
                className={cl("schedule_tour_standard")}
                name="email"
                placeholder="Email"
                rules={{
                  required: "Please enter a valid email.",
                  pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                }}
              />
            </div>

            <div className={cl("right_wrapper")}>
              <StandardInput
                // disabled={!openBookTour}
                label={""}
                className={cl("schedule_tour_standard")}
                placeholder="Phone Number"
                name="phone"
                rules={{ required: "Please enter a valid phone number." }}
              />
            </div>
          </div>
        ) : (
          <>
            <div className={cl("full_width_spacer")}>
              <StandardInput
                // disabled={!openBookTour}
                label={""}
                className={cl("schedule_tour_standard")}
                name="email"
                placeholder="Email"
                rules={{
                  required: "Please enter a valid email.",
                  pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                }}
              />
            </div>
            <div className={cl("full_width_spacer")}>
              <StandardInput
                // disabled={!openBookTour}
                label={""}
                className={cl("schedule_tour_standard")}
                placeholder="Phone Number"
                name="phone"
                rules={{ required: "Please enter a valid phone number." }}
              />
            </div>
          </>
        )}

        <div className={cl("checkboxs_wrapper")}>
          <div className={cl("checkboxs_field_title")}>
            {" "}
            PREFERRED FLOORPLAN(S){" "}
          </div>
          <div className={cl("checkbox_option_wrapper")}>
            <CheckboxInput
              // disabled={!openBookTour}
              name="studio"
              className={cl("control")}
              label={"Studio"}
              value={"Studio"}
              inputChange={() => layoutToggle("Studio")}
            />
            <CheckboxInput
              // disabled={!openBookTour}
              name="1br"
              className={cl("control")}
              label={"One Bedroom"}
              value={"One Bedroom"}
              inputChange={() => layoutToggle("One Bedroom")}
            />
            <CheckboxInput
              // disabled={!openBookTour}
              name="1br w/ Home Office"
              className={cl("control")}
              label={"One Bedroom w/ Home Office"}
              value={"One Bedroom w/ Home Office"}
              inputChange={() => layoutToggle("One Bedroom w/ Home Office")}
            />
            <CheckboxInput
              // disabled={!openBookTour}
              name="2br"
              className={cl("control")}
              label={"Two Bedroom"}
              value={"Two Bedroom"}
              inputChange={() => layoutToggle("Two Bedroom")}
            />
            <CheckboxInput
              // disabled={!openBookTour}
              name="3br"
              className={cl("control")}
              label={"Three Bedroom"}
              value={"Three Bedroom"}
              inputChange={() => layoutToggle("Three Bedroom")}
            />
          </div>
        </div>

        {!id && type !== "unit" ? (
          <div className={cl("row_wrapper")}>
            <div className={cl("left_wrapper")}>
              <SelectInput
                name="max_price"
                options={price_options}
                className={cl("schedule_tour_select")}
                placeholder="Max Budget (Per Month)"
              />
            </div>
            <div className={cl("right_wrapper")}>
              <DayPickerInput placeholder="Move-In Date" name="move_in_date" />
            </div>
          </div>
        ) : (
          <>
            <div className={cl("full_width_spacer")}>
              <DayPickerInput placeholder="Move-In Date" name="move_in_date" />
            </div>
            <div className={cl("full_width_spacer")}>
              <SelectInput
                name="max_price"
                options={price_options}
                className={cl("schedule_tour_select")}
                placeholder="Max Budget (Per Month)"
              />
            </div>
          </>
        )}

        <TextareaInput
          label={""}
          className={cl("schedule_tour_message")}
          name="notes"
          placeholder="Message"
          // disabled={!openBookTour}
        />
        <div className={cl("checkboxs_wrapper_broker")}>
          <div className={cl("checkboxs_wrapper")}>
            <legend className={cl("broker_title")}>Are you a broker?</legend>
            <div
              className={cl([
                "checkbox_option_wrapper_layout",
                "broker_checkbox",
              ])}
            >
              <CheckboxInput
                // disabled={!openBookTour}
                name="Broker_booked"
                className={cl("control")}
                label={"Yes"}
                value={false}
                inputChange={() => toggleBroker()}
              />
            </div>
          </div>
          {broker && (
            <div className={cl("full_width_broker")}>
              <StandardInput
                // disabled={!openBookTour}
                label={""}
                className={cl("schedule_tour_standard")}
                placeholder="Brokerage Firm"
                name="brokerage_firm"
                rules={{ required: "Please enter your brokerage firm." }}
              />
            </div>
          )}
        </div>

        <p className={cl("disclaimer")}>
          By selecting Submit, you agree that Two Trees and its affiliates may
          call/text you about your inquiry, which may involve use of automated
          means and prerecorded/artificial voices. You don&apos;t need to
          consent as a condition of buying any property, goods, or services.
          Message/data rates may apply.
        </p>

        <SubmitInput
          text={"Submit"}
          className={cl("schedule_tour_submit_field")}
        />
      </FormProvider>
      {type !== "unit" && <div className={cl("spacer")}></div>}
    </div>
  );
}

ScheduleTourForm.propTypes = {};

export default ScheduleTourForm;
