import { createContext, useContext, useState } from "react";
import Modal from "../widgets/Modal";

const ModalContext = createContext({});

import PropTypes from "prop-types";
ModalProvider.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export function ModalProvider({ children, className }) {
  const [visible, setVisibility] = useState();
  const [modalChildren, setChildren] = useState(null);
  const [modalClass, setModalClass] = useState();

  const openModal = (node, modalContainerClass) => {
    setVisibility(true);
    setChildren(node);
    setModalClass(modalContainerClass)
  };

  function closeModal() {
    setVisibility(null);
    setChildren(null);
  }

  return (
    <ModalContext.Provider
      value={{
        visible,
        openModal,
        closeModal,
        className
      }}
    >
      <Modal visible={visible} close={closeModal} children={modalChildren} className={className} modalContainer={modalClass} />
      {children}
    </ModalContext.Provider>
  );
}

export default function useModalContext() {
  return useContext(ModalContext);
}
