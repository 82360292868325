import PropTypes from "prop-types";
SubmitField.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  telephone: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

SubmitField.defaultProps = {
  text: "Submit",
  telephone: false,
};

export default function SubmitField({ text, className, telephone, ariaLabel }) {
  return (
    <div className={`field field-submit${className ? ` ${className}` : ""}`}>
      <div className={"control"}>
        {telephone ? (
          <a href={`tel:+1${text.replace("-", "")}`} className="submit">
            {text}
          </a>
        ) : (
          <button
            className="submit"
            tabIndex="0"
            aria-label={ariaLabel ? ariaLabel : text}
          >
            {text}
          </button>
        )}
      </div>
    </div>
  );
}
