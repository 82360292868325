import Link from "./Link";
import PropTypes from "prop-types";

SkipNav.propTypes = {
  link: PropTypes.string,
};

function SkipNav({ link }) {
  return <Link path={link}>Skip to main content</Link>;
}

export default SkipNav;
