export const submitFormspree = () => {};

export const submitShare = () => {};

export const nestioGroupIds = {
  DUMBO: [60, 61], // dumbo
  Williamsburg: [119, 596],
  "Fort Greene / Downtown Brooklyn": [1],
  "Brooklyn Heights / Cobble Hill": [62],
  "Hell's Kitchen": [63],
};

export const layoutsMap = {
  Studio: "studio",
  "Alcove Studio": "studio",
  "One Bedroom": "1br",
  "1 Bedroom": "1br",
  "One Bedroom w/ Home Office": ["1br", "2br"],
  "Two Bedroom": "2br",
  "2 Bedroom": "2br",
  "3 Bedroom": "3br",
};

export const shortTermNestioIds = {
  DUMBO: [60], // dumbo
  "Brooklyn Heights / Cobble Hill": [62],
};

export const resNeighborhoodsWithProperties = [
  {
    neighborhood: "DUMBO",
    properties: [
      { name: "60 Water Street", groupID: "2", leadSource: "DUMBO Waitlist" },
      {
        name: "25 Washington Street",
        groupID: "5316",
        leadSource: "DUMBO Waitlist",
      },
      {
        name: "30 Washington Street",
        groupID: "5317",
        leadSource: "DUMBO Waitlist",
      },
      {
        name: "65 Washington Street",
        groupID: "5318",
        leadSource: "DUMBO Waitlist",
      },
      {
        name: "81 Washington Street",
        groupID: "5320",
        leadSource: "DUMBO Waitlist",
      },
      {
        name: "66 Water Street",
        groupID: "5319",
        leadSource: "DUMBO Waitlist",
      },
      {
        name: "85 Water Street",
        groupID: "5321",
        leadSource: "DUMBO Waitlist",
      },
    ],
  },
  {
    neighborhood: "Williamsburg",
    properties: [
      {
        name: "One South First",
        groupID: "596",
        leadSource: "1s1 Waitlist",
      },
      { name: "325 Kent", groupID: "119", leadSource: "325 Kent Waitlist" },
    ],
  },
  {
    neighborhood: "Fort Greene / Downtown Brooklyn",
    properties: [
      {
        name: "300 Ashland",
        groupID: "1",
        leadSource: "Downtown Brooklyn Waitlist",
      },
    ],
  },
  {
    neighborhood: "Brooklyn Heights / Cobble Hill",
    properties: [
      {
        name: "125 Court Street",
        groupID: "5322",
        leadSource: "Brooklyn Heights Waitlist",
      },
      {
        name: "110 Livingston Street",
        groupID: "5312",
        leadSource: "Brooklyn Heights Waitlist",
      },
      {
        name: "164 Atlantic Avenue",
        groupID: "5314",
        leadSource: "Brooklyn Heights Waitlist",
      },
      {
        name: "200 Atlantic Avenue",
        groupID: "5315",
        leadSource: "Brooklyn Heights Waitlist",
      },
      {
        name: "138 Court Street",
        groupID: "5313",
        leadSource: "Brooklyn Heights Waitlist",
      },
    ],
  },
  {
    neighborhood: "Hell's Kitchen",
    properties: [
      {
        name: "Mercedes House",
        groupID: "5",
        leadSource: "Hell's Kitchen Waitlist",
      },
    ],
  },
];

// for Priority Waitlist only
export const defaultFormSpreeId = "xgerrqnz";
//IMPORTANT: both one south first and kent should be going to the williamsburg formspree ID, one domino we are checking for and passing accordingly.
export const wailistFormSpreeId = {
  default: defaultFormSpreeId,
  allNeighborhoods: defaultFormSpreeId,
  "Hell's Kitchen": "mjvjjzaq",
  "Fort Greene / Downtown Brooklyn": "xnqllrgy",
  Williamsburg: "mbjqqbzb",
  "One Domino Square": "xqkroakb",
  "Brooklyn Heights / Cobble Hill": "xrgrrdyj",
  DUMBO: "xvoddywv",
};

export const defaultLeadSource = "Generic Waitlist";
export const lead_sources = {
  default: defaultLeadSource,
  allNeighborhoods: defaultLeadSource,
  "Hell's Kitchen": "Hell's Kitchen Waitlist",
  "Fort Greene / Downtown Brooklyn": "Downtown Brooklyn Waitlist",
  Williamsburg: "Williamsburg Waitlist",
  williamsburg_kent: "325 Kent Waitlist",
  williamsburg_south: "1s1 Waitlist",
  "Brooklyn Heights / Cobble Hill": "Brooklyn Heights Waitlist",
  DUMBO: "DUMBO Waitlist",
};

export function getNeighborhoodProperties(neighborhood, allProperties) {
  const properties = [];
  allProperties?.forEach((property) => {
    if (property?.parentid === neighborhood?.id) {
      properties?.push(property);
    }
  });
  return properties;
}
