import Logo from "../header/Logo";
import logo from "../../../images/tt_logo_stacked_white.svg";
import { _classes } from "../../utilities/helpers";
import styles from "../../../styles/partials/footer/footerLogo.scss";
import Button from "../../widgets/Button";
import useAppState from "../../providers/AppProvider";
const cl = _classes(styles);
import PropTypes from "prop-types";
FooterLogo.propTypes = {
  page: PropTypes.object,
};

function FooterLogo({ page }) {
  const { toggleContactUs, toggleApplyNow } = useAppState();
  if (!page) return null;
  const apply = page?.fieldgroup2[2].h1 ? page.fieldgroup2[2].h1 : null;
  const contact = page?.fieldgroup2[3].h1 ? page.fieldgroup2[3].h1 : null;
  return (
    <div className={cl("_")}>
      <div className={cl("spacer1")} />
      <div className={cl("spacer2")}>
        <Logo
          className={cl("logo")}
          logo={logo}
          path="/"
          alt="Two Tree"
          ariaLabel="Navigate back to home page"
        />
        <div className={cl("address")}>{page.address1}</div>
      </div>
      <div className={cl("spacer3")}>
        <Button
          className={cl("apply")}
          text={apply}
          onClick={toggleApplyNow}
          ariaLabel="Apply to a building"
        ></Button>
        <Button
          className={cl("contact")}
          text={contact}
          onClick={toggleContactUs}
          ariaLabel="Contact Us"
        ></Button>
      </div>
    </div>
  );
}

export default FooterLogo;
