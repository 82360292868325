/* Form Component.
 *
 * onSubmit can be a string indicating the name of the endpoint
 * to post to on our server, or a custom function that returns
 * a promise
 */

import { createContext, useContext, useState } from "react";
import { useForm } from "react-hook-form/dist/index.ie11";
import API from "../utilities/API";
import axios from "axios";
import { useAppState } from "../providers";
import { useEffect } from "react";
const FormContext = createContext({});

FormProvider.propTypes = {
  children: PropTypes.node,
  onSubmit: PropTypes.oneOfType(["function", "string"]).required,
  additionalData: PropTypes.object,
  formSpreeID: PropTypes.string,
  thankyouMessage: PropTypes.func,
  formClass: PropTypes.string,
  type: PropTypes.string,
  thirdPartySuccess: PropTypes.oneOfType(["function", "bool"]),
};

import PropTypes from "prop-types";
FormProvider.propTypes = {
  children: PropTypes.node,
  onSubmit: "",
  additionalData: {},
  formSpreeID: "",
  thankyouMessage: null,
  type: "",
  thirdPartySuccess: false,
};

export default function FormProvider({
  children,
  additionalData,
  formSpreeID,
  onSubmit,
  thankyouMessage,
  formClass,
  type,
  thirdPartySuccess,
}) {
  const { handleSubmit, control, errors, register, reset } = useForm();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const renderFields = () => !success && children;
  const { setIncentiveSuccess, resetForm, useRef_contactform } = useAppState();

  useEffect(() => {
    if (thirdPartySuccess) {
      setSuccess(true);
    } else {
      setSuccess(false);
      reset();
    }
  }, [thirdPartySuccess]);
  useEffect(() => {
    if (success && type !== "incentive" && type !== "residential_rental_form") {
      setTimeout(() => {
        setSuccess(false);
      }, 10000);
    } else if (type === "incentive") {
      if (resetForm) {
        setSuccess(false);
        setIncentiveSuccess(false);
        reset();
      }
    }
  }, [success, resetForm]);

  const renderThankYou = () => {
    if (success) {
      return (
        <div className="form__thank_you">
          {thankyouMessage ? (
            thankyouMessage()
          ) : (
            <p
              id="thank-you"
              tabIndex="0"
              aria-label="Thank you! A Leasing Associate will be in touch with you shortly."
            >
              Thank you! A Leasing Associate will be in touch with you shortly.
            </p>
          )}
        </div>
      );
    }
  };

  const renderError = () =>
    error &&
    !success && (
      <div>
        <p>{error}</p>
      </div>
    );

  const submitFormSpree = (formData) => {
    const formSpreeData = new FormData();
    for (let key in formData) {
      formSpreeData.append(key, formData[key]);
    }
    formSpreeData.append("email", formData.your_email);
    const config =
      onSubmit === "share"
        ? {
            url: `https://formspree.io/${formSpreeID}`,
            method: "post",
            crossDomain: true,
            data: formSpreeData,
            dataType: "json",
          }
        : {
            url: `https://formspree.io/${formSpreeID}`,
            method: "post",
            crossDomain: true,
            headers: {
              Accept: "application/json",
            },
            data: formData,
            dataType: "json",
          };
    axios(config).then((response) => {
      if (response.error) {
        return setError(error.message || "An error occurred.");
      }
      setIncentiveSuccess(true);
      setSuccess(true);
    });
  };
  const submitHandler = handleSubmit((formData) => {
    // console.log("Raw FormData", formData);
    const combinedData = { ...formData, ...additionalData };
    // eslint-disable-next-line no-undef
    let api = new API({ base: API_PATH });
    let submit = api.post.bind(api, onSubmit);
    if (formSpreeID) submitFormSpree(combinedData);
    if (typeof onSubmit === "function" && type === "searchForm") {
      submit = onSubmit(combinedData);
    }
    if (typeof onSubmit === "function" && type === "applyNow") {
      submit = onSubmit(combinedData);
    }
    if (
      typeof onSubmit === "function" &&
      type !== "searchForm" &&
      type !== "applyNow"
    ) {
      submit = onSubmit(combinedData);
      if (thirdPartySuccess) {
        setSuccess(true);
      }
    }

    if (typeof onSubmit === "string" && onSubmit !== "") {
      submit(combinedData)
        .then((resp) => {
          if (resp.error) {
            return setError(error.message || "An error occurred.");
          }
          setSuccess(true);
          setIncentiveSuccess(true);
        })
        .catch((error) => {
          console.error(error);
          setError(error.message || "An error occurred.");
        });
    }
  });

  return (
    <FormContext.Provider
      value={{
        control,
        register,
        errors,
      }}
    >
      <form className={`form ${formClass}`} onSubmit={submitHandler}>
        {!success && renderFields()}
        {renderThankYou(useRef_contactform)}
        {renderError()}
      </form>
    </FormContext.Provider>
  );
}

export function useFormContext() {
  return useContext(FormContext);
}
