import { useAppState } from "../../providers";
import { _classes } from "../../utilities/helpers";
import styles from "../../../styles/partials/footer/FooterBuildings.scss";
import Link from "../../widgets/Link";
const cl = _classes(styles);

import PropTypes from "prop-types";
FooterBuildings.propTypes = {
  className: PropTypes.string,
};
function FooterBuildings({ title, buildings, type, className }) {
  const {
    childrenById,
    byTemplate,
    toggleFilter,
    resetAllFilters,
    availabilitiesFilters,
    history,
  } = useAppState();
  const neighborhoodPath = byTemplate("neighborhoods");
  // example of sorting on CMS

  const scrollpageTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleClick = (value) => {
    if (availabilitiesFilters.length === 0) {
      toggleFilter(value);
      scrollpageTop();
    } else {
      resetAllFilters();
      setTimeout(() => {
        toggleFilter(value);
      }, 500);
      setTimeout(() => {
        scrollpageTop();
      }, 500);
    }
  };
  const serialize = (obj) => {
    const str = [];
    for (var p in obj)
      if (obj.hasOwn(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  };
  const submitData = (data) => {
    let obj = { neighborhood: data };
    let params = serialize(obj);
    const url = `/availabilities/office-spaces${params ? `?${params}` : ""}`;

    return history.push(url);
  };

  const renderNeighborhood = () => {
    if (buildings && buildings.length > 0) {
      return buildings.map((element, index) => {
        if (type === "residential") {
          return (
            <div className={cl("neighborhood")} key={element.id}>
              <Link
                className={cl("neighborhood_title")}
                title={element.h1}
                path={
                  neighborhoodPath[index] === undefined
                    ? "/"
                    : neighborhoodPath[index].path
                }
              />
              <div className={cl("list_of_buildings_container")}>
                {renderBuilding(element)}
              </div>
            </div>
          );
        } else if (type === "commerical") {
          return (
            // element.id is the build's id on cms
            <div className={cl("neighborhood")} key={element.id}>
              <a
                className={cl("neighborhood_title")}
                onClick={() => {
                  {
                    submitData(element.h1);
                    handleClick(element.id);
                  }
                }}
                href={`/availabilities/office-spaces?neighborhood=${element.h1}`}
              >
                {element.h1}
              </a>
              <div className={cl("list_of_buildings_container")}>
                {renderBuilding(element)}
              </div>
            </div>
          );
        }
      });
    }
  };

  const renderBuilding = (element) => {
    const buildings = childrenById(element.id).sort();
    if (buildings && buildings.length > 0) {
      return buildings.map((building) => {
        return (
          <div className={cl("building")} key={building.id}>
            <Link
              className={cl("link")}
              title={building.linktitle}
              path={building.path}
            />
          </div>
        );
      });
    }
  };

  return (
    <div className={cl(["_", className])}>
      <div className={cl("title")}>{title}</div>
      <div className={cl("residential_buildings")}>{renderNeighborhood()}</div>
    </div>
  );
}

export default FooterBuildings;
