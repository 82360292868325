import styles from "../../../styles/partials/header/headerLogoSection.scss";
import { _classes } from "../../utilities/helpers";
import Link from "../../widgets/Link";
import Button from "../../widgets/Button";
import Logo from "./Logo";
import logo from "../../../images/tt_logo_stacked.svg";
import useAppState from "../../providers/AppProvider";

const cl = _classes(styles);

import PropTypes from "prop-types";
HeaderLogoSection.propTypes = {
  logo: PropTypes.object,
  resize: PropTypes.bool,
};

function HeaderLogoSection({ resize }) {
  const { setOpenNav, toogleBookTour, toggleContactUs } = useAppState();
  const toggleNav = () => {
    setOpenNav((old) => !old);
  };

  return (
    <header className={cl(["_", resize && "resize"])}>
      <Link
        className={cl("availabilities")}
        title={"Availabilities"}
        path={"/availabilities/apartments"}
      ></Link>
      <div className={cl("left_buttons")}>
        <Link
          className={cl("view_availabilities")}
          title={"view availabilities"}
          path={"/availabilities/apartments"}
        ></Link>
        <Button
          className={cl("book_a_tour")}
          text={"book a tour"}
          onClick={toogleBookTour}
        ></Button>
      </div>
      <Logo
        className={cl(["logo", resize && "logo_small"])}
        logo={logo}
        path="/"
        alt="Two Trees logo"
        ariaLabel="Navigate back to home page"
      />
      <div className={cl("right_buttons")}>
        <Link
          className={cl("number")}
          title={"718-907-5800"}
          path={"tel:+1-718-907-5800"}
          ariaLabel="Phone number: 718-907-5800"
        ></Link>
        <Button
          className={cl("contact")}
          text={"contact"}
          onClick={toggleContactUs}
          ariaLabel="contact us"
        ></Button>
      </div>
      {/* use this one to trigger the toggle menu */}
      <Button
        className={cl("menu")}
        text={"Menu"}
        onClick={toggleNav}
        ariaLabel="Open the main menu"
      ></Button>
    </header>
  );
}

export default HeaderLogoSection;
