import styles from "../../styles/views/cookie_popup.scss";
import useAppState from "../providers/AppProvider";
import React, { useEffect, useState } from "react";

CookiePopup.propTypes = {};

function CookiePopup() {
  const { byTemplate } = useAppState();
  const page = byTemplate("footer")[0];
  if (!page) return null;
  const [sessionCookieTrue, setSessionCookieTrue] = useState(null);

  // console.log("cookies", cookiesAllowed);

  useEffect(() => {
    //Cookie consent logic
    const hasAcceptedCookie = localStorage.getItem("cookiesAccepted");
    const hasDeclinedCookie = localStorage.getItem("cookiesDeclined");

    const cookieExpirationDate =
      getLocalStorageDeclined() || getLocalStorageAccepted() || false;

    // console.log("cookieExpirationDate", cookieExpirationDate);
    const now = new Date();

    if (
      hasAcceptedCookie ||
      (hasDeclinedCookie && cookieExpirationDate >= now)
    ) {
      setSessionCookieTrue(true);
    }
  }, []);

  const declineGoogleConsent = () => {
    if (window.gtag) {
      window.gtag("consent", "update", {
        ad_storage: "declined",
        ad_user_data: "declined",
        ad_personalization: "declined",
        analytics_storage: "declined",
      });
    }
  };
  const deleteLocalStorage = (key) => {
    localStorage.removeItem(key);
  };
  const getLocalStorageAccepted = () => {
    return new Date(localStorage.getItem("cookiesAcceptedExpiration"));
  };
  const getLocalStorageDeclined = () => {
    return new Date(localStorage.getItem("cookiesDeclinedExpiration"));
  };

  const setAcceptCookieLocalStorage = () => {
    const expiration = new Date();
    expiration.setDate(expiration.getDate() + 30);
    localStorage.setItem("cookiesAccepted", true);
    localStorage.setItem(
      "cookiesAcceptedExpiration",
      expiration?.toISOString()
    );
    setSessionCookieTrue(true);
    deleteLocalStorage("cookiesDeclined");
    deleteLocalStorage("cookiesDeclinedExpiration");
  };
  const setDeclineCookieLocalStorage = () => {
    declineGoogleConsent();
    const expiration = new Date();
    expiration.setDate(expiration.getDate() + 30);
    localStorage.setItem("cookiesDeclined", true);
    localStorage.setItem(
      "cookiesDeclinedExpiration",
      expiration?.toISOString()
    );
    setSessionCookieTrue(true);
    deleteLocalStorage("cookiesAccepted");
    deleteLocalStorage("cookiesAcceptedExpiration");
  };

  if (sessionCookieTrue) return null;
  return (
    <div className={styles.cookiePopup}>
      <div className={styles.cookiePopup__inner}>
        <div
          className={`${styles.cookiePopup__blurb}`}
          dangerouslySetInnerHTML={{ __html: page.blurb1 }}
        />
        <div className={styles.cookiePopup__buttons}>
          <button
            className={styles.cookiePopup__button}
            onClick={setAcceptCookieLocalStorage}
            aria-label="Accept Cookies"
            tabIndex="0"
          >
            Accept
          </button>
          <button
            className={styles.cookiePopup__button}
            onClick={setDeclineCookieLocalStorage}
            aria-label="Decline Cookies"
            tabIndex="0"
          >
            Decline
          </button>
        </div>
      </div>
    </div>
  );
}

export default CookiePopup;
