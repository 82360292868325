import { useState } from "react";
import {
  SubmitInput,
  FormProvider,
  StandardInput,
  CheckboxInput,
} from "../../form";
import styles from "../../../styles/partials/forms/residentialRental-form.scss";
import { _classes } from "../../utilities/helpers";
import pkg from "../../../../package.json";

const cl = _classes(styles);
CondoRentalForm.propTypes = {};

export default function CondoRentalForm() {
  const [isBroker, setIsBroker] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const apiRoot = pkg.config.DEV_SITE;

  const handleSubmit = async (formData) => {
    const floorPlans = [
      formData.one_bedroom && "One Bedroom",
      formData.two_bedroom && "Two Bedroom",
      formData.three_bedroom && "Three Bedroom",
      formData.penthouse && "Penthouse",
    ].filter(Boolean); // Filter out falsy values (e.g., undefined, false)

    // Join the remaining values with commas
    const selectedFloorPlans = floorPlans.join(", ");
    //TODO: Add the integration submission here
    const dataToSubmit = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      phone: formData.phone,
      floor_plan: selectedFloorPlans,
      is_broker: formData.is_broker,
      broker: formData.broker,
      lead_source: "Two Trees Website",
    };
    // console.log("Data Being Submitted", dataToSubmit);

    //Using Fetch, Post to an endpoint named /api/contact_us
    const response = await fetch(`${apiRoot}api/contact_us`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataToSubmit),
    });

    const res = await response.json();
    if (res?.status === "success") {
      setFormSubmitted(true);
    }
  };

  const handleBrokerSelect = (e) => {
    setIsBroker(e.target.checked);
  };

  const renderThankYou = () => {
    return (
      <div
        id="residential-form-thank-you"
        className={cl(["form__thank_you", "thank_you_message_tracker"])}
      >
        Thank you! An Associate will be in touch with you shortly!
      </div>
    );
  };

  return (
    <div className={cl("residential_rental_form")}>
      <FormProvider
        onSubmit={handleSubmit}
        thankyouMessage={() => renderThankYou()}
        type={"residential_rental_form"}
        thirdPartySuccess={formSubmitted}
      >
        <StandardInput
          className={cl("residential_rental_standard")}
          label={""}
          name="first_name"
          placeholder="First Name"
          rules={{ required: "Please enter your first name." }}
        />
        <StandardInput
          className={cl("residential_rental_standard")}
          label={""}
          name="last_name"
          placeholder="Last Name"
          rules={{ required: "Please enter your last name." }}
        />
        <StandardInput
          label={""}
          className={cl("residential_rental_standard")}
          name="email"
          placeholder="Email"
          rules={{
            required: "Please enter a valid email.",
            pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          }}
        />
        <StandardInput
          label={""}
          className={cl("residential_rental_standard")}
          placeholder="Phone Number"
          name="phone"
          rules={{ required: "Please enter a valid phone number." }}
        />
        <div className={cl("checkboxs_wrapper")}>
          <div className={cl("checkboxs_field_title")}>
            {" "}
            PREFERRED FLOORPLAN(S){" "}
          </div>
          <div
            className={cl([
              "checkbox_option_wrapper_layout",
              "checkbox_option_wrapper_layout_condo",
            ])}
          >
            <CheckboxInput
              name="one_bedroom"
              className={cl("control")}
              label={"One Bedroom"}
              value={"One Bedroom"}
            />

            <CheckboxInput
              name="two_bedroom"
              className={cl("control")}
              label={"Two Bedroom"}
              value={"Two Bedroom"}
            />
            <CheckboxInput
              name="three_bedroom"
              className={cl("control")}
              label={"Three Bedroom"}
              value={"Three Bedroom"}
            />
            <CheckboxInput
              name="penthouse"
              className={cl("control")}
              label={"Penthouse"}
              value={"Penthouse"}
            />
          </div>
        </div>
        <div className={cl("broker")}>
          <fieldset className={cl("checkboxs_wrapper")}>
            <legend className={cl("broker_title")}>Are you a broker?</legend>
            <div
              className={cl([
                "checkbox_option_wrapper_layout",
                "broker_checkbox",
              ])}
            >
              <CheckboxInput
                name="is_broker"
                className={cl("control")}
                label={"Yes"}
                value={"yes"}
                inputChange={(e) => handleBrokerSelect(e)}
              />

            </div>
          </fieldset>
          {isBroker && (
            <StandardInput
              label={""}
              className={cl("residential_rental_standard")}
              name="broker"
              placeholder="Brokerage Name"
              rules={{ required: "Please enter your brokerage name" }}
            />
          )}
        </div>
        <p className={cl("disclaimer")}>
          By selecting Submit, you agree that Two Trees and its affiliates may
          call/text you about your inquiry, which may involve use of automated
          means and prerecorded/artificial voices. You don&apos;t need to
          consent as a condition of buying any property, goods, or services.
          Message/data rates may apply.
        </p>
        <SubmitInput
          text={"Submit"}
          className={cl("residential_rental_submit_field")}
        />
      </FormProvider>
    </div>
  );
}
