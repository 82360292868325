import PropTypes from "prop-types";
ErrorMessage.propTypes = {
  error: PropTypes.object,
};

export default function ErrorMessage({ error }) {
  return error ? (
    <span className={"field-error"}>{error.message || "Error"}</span>
  ) : null;
}
