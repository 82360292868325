// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html:not([class*=accessible]) .header______bnpoM{width:100%}html:not([class*=accessible]) .header______bnpoM.header__resize___O1QaK nav{height:38px}html:not([class*=accessible]) .header__wrapper___lz9_5{position:fixed;top:0;left:0;z-index:4;width:100%}", "",{"version":3,"sources":["webpack://./src/styles/partials/header.scss"],"names":[],"mappings":"AAEE,iDACE,UAAA,CAOE,4EACE,WAAA,CAIN,uDACE,cAAA,CACA,KAAA,CACA,MAAA,CACA,SAAA,CAKA,UAAA","sourcesContent":["@import \"../global/parts\";\n@include _notAccessible {\n  ._ {\n    width: 100%;\n    // height: auto;\n    @include _minWidthTablet {\n      // height: auto;\n    }\n\n    &.resize {\n      nav {\n        height: 38px;\n      }\n    }\n  }\n  .wrapper {\n    position: fixed;\n    top: 0;\n    left: 0;\n    z-index: 4;\n    // height: 159px;\n    @include _minWidthTablet {\n      // height: 178px;\n    }\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_": "header______bnpoM",
	"resize": "header__resize___O1QaK",
	"wrapper": "header__wrapper___lz9_5"
};
export default ___CSS_LOADER_EXPORT___;
