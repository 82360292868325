import { motion } from "../../utilities/FramerMotion";
import useAppState from "../../providers/AppProvider";
import ResidentialRentalForm from "../forms/ResidentialRentalForm";
import styles from "../../../styles/partials/contact_us_popup/contact_us_formContianer.scss";
import { _classes } from "../../utilities/helpers";
const cl = _classes(styles);
ResidentialFormContainer.propTypes = {};

function ResidentialFormContainer() {
  const { activateResidential } = useAppState();
  // const variants = {
  //     animate: {
  //       y: !activateResidential ? -500 : 0,
  //       transition: { duration: 0.3, times: [0, 0.2, 1] },
  //     },
  //     initial: { y: -500 },
  //   }
  return (
    <motion.div className={cl("_")}>
      {activateResidential && <ResidentialRentalForm />}
    </motion.div>
  );
}

export default ResidentialFormContainer;
