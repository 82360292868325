import chrome from "./../../../images/chrome.jpg";
import ie from "./../../../images/ie.jpg";
import styles from "./InvalidBrowser.scss";

const InvalidBrowser = () => (
  <div className={styles.invalidBrowser}>
    <div className={styles.inner}>
      <div className={styles.content}>
        <div className={styles.images}>
          <img src={ie} />
          <img src={chrome} />
        </div>
        <div className={styles.text}>
          <p>
            {`This website no longer supports Internet Explorer 10 or below. Please `}
            <a
              href="http://windows.microsoft.com/en-us/internet-explorer/download-ie"
              target="_blank" rel="noreferrer"
            >
              {`upgrade to the latest version of Internet Explorer`}
            </a>
            {` or visit using `}
            <a
              href="https://www.google.com/chrome/browser/desktop/"
              target="_blank" rel="noreferrer"
            >
              {`another web browser.`}
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default InvalidBrowser;
