import { SubmitInput, FormProvider, SelectInput } from "../../form";
import { useAppState } from "../../providers";
import styles from "../../../styles/partials/forms/commercialRental-form.scss";
import { _classes, parse } from "../../utilities/helpers";
const cl = _classes(styles);
ApplyNowForm.propTypes = {};

function ApplyNowForm() {
  const { byTemplate, applyBuilding, setApplyBuilding, history } =
    useAppState();
  const buildingPages = byTemplate("building");
  const buildingOrder =
    byTemplate("global")[0]?.fieldgroup5.map((item) => item.h1) || [];

  const residentialPages = buildingPages
    ?.filter((building) => !building.path.includes("/office-spaces/"))
    .sort((a, b) => {
      return (
        buildingOrder.indexOf(a.linktitle) - buildingOrder.indexOf(b.linktitle)
      );
    });

  const apply_options = residentialPages
    .map((option) => {
      const cta = parse(option.buttonlink1);
      if (cta && cta.text !== "" && cta.link !== "") {
        return {
          label: parse(option.buttonlink1).text,
          value: parse(option.buttonlink1).link,
          id: Math.floor(Math.random() * 600000),
        };
      }
    })
    .filter((i) => i !== undefined);

  //Take apply

  const submitData = () => {
    if (applyBuilding.value === undefined) {
      return history.push(applyBuilding);
    } else {
      return window.open(`${applyBuilding.value}`, "_blank");
    }
  };
  const handleChange = (event) => {
    setApplyBuilding(event);
  };
  return (
    <div className={cl("apply_now_form")}>
      <FormProvider onSubmit={() => submitData()} type={"applyNow"}>
        <SelectInput
          name="dropdown"
          options={apply_options}
          className={cl("apply_now_select")}
          placeholder="All Neighborhoods"
          label={""}
          onSelect={handleChange}
        />
        <SubmitInput text={"Select"} className={cl("apply_now_submit_field")} />
      </FormProvider>
    </div>
  );
}

export default ApplyNowForm;
