import React from "react";
import { createPortal } from "react-dom";
import useAppState from "../../providers/AppProvider";
import { _classes } from "../../utilities/helpers";
import styles from "../../../styles/partials/apply_now/apply_now.scss";
import { motion } from "../../utilities/FramerMotion";
import CustomScroll from "react-custom-scroll";
import ApplyNowForm from "../forms/ApplyNowForm";
import { useEffect } from "react";
const cl = _classes(styles);

function ApplyNow() {
  const { openApplyNow, closeApplyNow, byTemplate } = useAppState();
  const page = byTemplate("apply_now")[0] || {};
  const variants = {
    animate: {
      x: !openApplyNow ? 400 : 0,
      transition: { duration: 0.3, times: [0, 0.2, 1] },
    },
    initial: { x: 400 },
  };

  const portalRoot = document.querySelector(".applyModal");
  const el = document.createElement("div");

  useEffect(() => {
    if (portalRoot && el) portalRoot.appendChild(el);
    return () => portalRoot.removeChild(el);
  }, []);

  React.useEffect(() => {
    const activeElement = document.activeElement;
    document.addEventListener("keydown", handleKeydown);
    return () => {
      // Detach listener when component unmounts
      document.removeEventListener("keydown", handleKeydown);
      // Return focus to the previously focused element
      activeElement.focus();
    };
  }, []);

  // Map key codes to their respective event handlers
  const keyListenersMap = new Map([
    // Hint: keycode 13 is the enter key
    [13, handleKeydown],
  ]);

  const handleKeydown = (evt) => {
    // get the listener corresponding to the pressed key
    const listener = keyListenersMap.get(evt.keyCode);

    // call the listener if it exists
    return listener && listener(evt);
  };

  return createPortal(
    <motion.div className={cl("_")} {...variants}>
      <div className={cl(["background", openApplyNow && "active"])}></div>
      <div className={cl("form_container")}>
        <div className={cl("scrollWrapper")}>
          <CustomScroll>
            <div>
              <div className={cl("title_wrapper")}>
                <div className={cl("title")} tabIndex="0">
                  {page.h1}
                </div>
                <div className={cl("close_button_wrapper")}>
                  <button
                    className={cl("close_button")}
                    onClick={closeApplyNow}
                    onKeyUp={(e) => {
                      e.key === "Enter" && closeApplyNow();
                    }}
                    tabIndex="0"
                    aria-label="close Apply Now form"
                  />
                </div>
              </div>
              <div
                className={cl("intro")}
                dangerouslySetInnerHTML={{ __html: page.blurb1 }}
              ></div>
              <div className={cl("subtitle")}>{page.h2}</div>
            </div>
            <ApplyNowForm />
          </CustomScroll>
        </div>
      </div>
    </motion.div>,
    document.querySelector(".applyModal")
  );
}

ApplyNow.propTypes = {};

export default ApplyNow;
