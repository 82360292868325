import React from "react";
import Link from "../../widgets/Link";
import { _classes, insert } from "../../utilities/helpers";
import styles from "../../../styles/partials/header/sub-nav.scss";
import useAppState from "../../providers/AppProvider";
const cl = _classes(styles);
import PropTypes from "prop-types";
SubNav.propTypes = {
  items: PropTypes.array,
  parent: PropTypes.object,
};
SubNav.defaultProps = {
  items: [],
};

export default function SubNav({ items, parent }) {
  const filterItems = (item) => item.showinnav;
  const { byTemplate, toggleApplyNow, setPropertyType } = useAppState();
  const availabilities = byTemplate("availabilities");
  let nav_items = () => {
    if (parent.path === "/apartments") {
      return [
        ...items,
        {
          ...availabilities.find((p) => p.path.includes("apartments")),
          linktitle: "View Availabilities",
        },
      ].filter(filterItems);
    } else if (parent.path === "/office-spaces") {
      let sub_items = insert(items, 2, {
        ...availabilities.find((p) => p.path.includes("office-spaces")),
        linktitle: "View Availabilities",
      });
      return sub_items.filter(filterItems);
    } else {
      return items.filter(filterItems);
    }
  };
  if (items && !items.length) return false;
  else {
    const apply_now = nav_items().find(ele => ele.title.toLowerCase() === "apply now") || false;
    const reArraged =  nav_items().filter(ele=> { return ele.title.toLowerCase() !== "apply now"})
    if (apply_now) {
      reArraged.push(apply_now)
    }
    return (
      <ul className={cl("_")}>
        {reArraged.map((item) => {
          if (item.linktitle === "View Availabilities" && item.pagetitle.toLowerCase()=== "office spaces") {
            return (
              <li key={item.id}>
              <Link
                title={item.linktitle}
                onClick={() => setPropertyType("commercial")}
                className={cl(["availabilities_link"])}
                path={item.path}
              ></Link>
            </li>
            )
          }

          else if (item.linktitle === "View Availabilities" && item.pagetitle.toLowerCase()=== "apartments") {
            return (
              <li key={item.id}>
              <Link
                title={item.linktitle}
                onClick={() => setPropertyType("residential")}
                className={cl(["availabilities_link"])}
                path={item.path}
              ></Link>
            </li>
            )
          }

          else if (item.h1.toLowerCase() !== "apply now") {
            return (
              <li key={item.id} className={cl(["item"])}>
                <Link
                  title={item.linktitle}
                  path={item.path}
                  className={cl(["link"])}
                >
                  {item.linktitle}
                </Link>
              </li>
            );
          } else if (item.h1.toLowerCase() === "apply now") {
            return (
              <li key={item.id}>
                <Link
                  title={item.linktitle}
                  onClick={toggleApplyNow}
                  className={cl(["apply_now_link"])}
                ></Link>
              </li>
            );
          } 
        })}
      </ul>
    );
  }
}
