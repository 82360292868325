import React from "react";
import Reveal from "../../widgets/Reveal";
import PropTypes from "prop-types";
export default class HubspotContainer extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    formId: PropTypes.string.isRequired,
    className: PropTypes.string,
    reveal: PropTypes.bool,
    onSubmit: PropTypes.func,
  };

  static defaultProps = {
    className: "",
    reveal: true,
    onSubmit: () => null,
  };

  constructor(props) {
    super(props);

    this.portalId = "7941245";
    this.state = {
      submitted: false,
    };
  }

  componentDidMount() {
    this.initJQuery();
    const { formId } = this.props;
    const script = document.getElementById(`"__hbspt_script__"`);
    // console.log("changes", document.querySelector(".hubspot_node"));

    if (formId) {
      window.hbspt
        ? this.createForm()
        : (script.onload = () => window.hbspt && this.createForm());
    }
  }
  handleScroll = (useRef_contactform) => {
    if (useRef_contactform?.scrollTop !== 0) {
      useRef_contactform?.scroll({
        top: 0,
        behavior: "smooth",
      });
    }
  };
  componentDidUpdate(prevProps) {
    if (prevProps.formId && prevProps.formId !== this.props.formId) {
      this.createForm();
    }
    if (this.state.submitted === true) {
      this.handleScroll(this.props.useRef_contactform);
    }
  }

  initJQuery = () => {
    // these are all methods required by HubSpot in order to useonFormSubmitted callback
    window.jQuery =
      window.jQuery ||
      (() => ({
        change: () => {},
        trigger: () => {},
      }));
  };

  onSubmit = () => {
    if (!this.state.submitted) {
      this.props.onSubmit();
    }

    this.setState({ submitted: true });

    setTimeout(() => {
      this.createForm();
    }, 10000);
    setTimeout(() => {
      this.setState({ submitted: false });
    }, 10000);
  };

  createForm = () => {
    const { formId } = this.props;
    const form = window.hbspt.forms.create({
      portalId: this.portalId,
      formId,
      onFormSubmitted: this.onSubmit,
    });

    form.onReady(() => {
      // When the form is loaded, append the disclaimer to the end of the form
      const formParent = document.getElementById(
        `hsForm_${formId}`
      ).parentElement;
      const target = document.querySelector(".hubspot_node");

      // Remove any existing form elements
      while (target.firstChild) {
        target.removeChild(target.firstChild);
      }

      // Append the form
      target.appendChild(formParent);
    });
  };

  render() {
    const { title, formId, className, reveal } = this.props;

    if (formId && this.portalId && window.hbspt) {
      if (reveal) {
        return (
          <div className={`hubspot_form ${className}`}>
            <Reveal>
              {title && <h2>{title}</h2>}
              <div className="hubspot_node" ref={(r) => (this.node = r)} />
            </Reveal>
          </div>
        );
      } else {
        return (
          <div className={`hubspot_form ${className}`}>
            {title && <h2>{title}</h2>}
            <div className="hubspot_node" ref={(r) => (this.node = r)} />
          </div>
        );
      }
    }

    return <div className="hubspot_no_form" />;
  }
}
