import Link from "../../widgets/Link";
import styles from "../../../styles/partials/components/_cta.scss";
import { _classes } from "../../utilities/helpers";
import { leftArrow } from "../../widgets/SVG";
const cl = _classes(styles);

import PropTypes from "prop-types";
CTA.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  color: PropTypes.oneOf(["white", "green", "grey"]),
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  target: PropTypes.string,
  hasArrow: PropTypes.bool,
  arrowDirection: PropTypes.oneOf(["left", "right"]),
  tabIndex: PropTypes.string,
  search:PropTypes.string
};

CTA.defaultProps = {
  link: "",
  text: "",
  color: "green",
  onClick: () => null,
  className: "",
  size: "md",
  hasArrow: false,
  search:"",
};

function CTA({
  link,
  text,
  onClick,
  className,
  color,
  size,
  target,
  hasArrow,
  arrowDirection,
  tabIndex,
  search
}) {

  return (
    <Link
      className={`${cl(["_", color, size])}${className ? ` ${className}` : ""}`}
      path={link}
      search={search}
      ariaLabel={text}
      target={target}
      onClick={() => {
        onClick();
      }}
      tabIndex={tabIndex ? tabIndex : "0"}
    >
      {hasArrow ? (
        <span className={cl(["arrow", arrowDirection === "right" && "right"])}>
          {leftArrow}
        </span>
      ) : null}
      {text}
    </Link>
  );
}

export default CTA;
