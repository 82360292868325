import { createRoot } from "react-dom/client";
import "@babel/polyfill";
import "@babel/polyfill";
import "./styles/main.scss";
import "./markup/index.pug";
import "./scripts/utilities/ProxyPolyfill";
import "focus-visible";
import axios from "axios";
import App from "./scripts/App.js";
import API from "./scripts/utilities/API";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "lazysizes";

import {
  AppProvider,
  ModalProvider,
  ViewportProvider,
} from "./scripts/providers";
const getApiUrl = (route1, route2) => {
  let url = "https://nestiolistings.com/api/v2/listings/";
  if (route1) url = url + route1 + "/";
  if (route2) url = url + route2 + "/";
  return url;
};
const getNestioData = (route1, route2, options) => {
  return axios.get(getApiUrl(route1, route2), {
    params: {
      key: "0c44cf1104174061ad121134a3415451",
      has_photos: 1,
      ...options,
    },
  });
};

async function fetch() {
  try {
    // eslint-disable-next-line no-undef
    const api = new API({ base: API_PATH });
    const timeStamp = Date.now();
    const [
      { data: content },
      { data: slides },
      { data: nestion_residential },
      { data: commecial },
      { data: all_nestio_data },
    ] = await Promise.all([
      api.get(`content?cacheBuster=${timeStamp}`),
      api.get(`slides?cacheBuster=${timeStamp}`),
      getNestioData("residential", "rentals"),
      getNestioData("commercial", "rentals", {
        key: "ff41aea5f3e546d5aa4a7db92558c959",
      }),
      getNestioData("all"),
    ]);

    const data = Object.keys(content).reduce((sum, id) => {
      sum[id] = {
        ...content[id],
        ...slides[id],
      };
      return sum;
    }, {});
    const nestio = [nestion_residential, commecial];
    // console.log("%c Nestio", "color: fuschia; background-color: navy;", nestio);
    const residential = nestion_residential;
    return { data, residential, commecial, nestio, all_nestio_data };
  } catch (e) {
    console.error("Could not fetch app data: ", e);
    return { error: e };
  }
}

function render({ data, nestio, residential, commecial }) {
  if (data.error) {
    const root = createRoot(document.querySelector(".wrapper"));
    root.render(
      <div>
        <h1>There was a problem loading the site.</h1>
      </div>
    );
  } else {
    const root = createRoot(document.querySelector(".wrapper"));

    root.render(
      <Router>
        <Route
          render={(props) => (
            <AppProvider
              content={data}
              {...props}
              nestio={nestio}
              residential={residential}
              commecial={commecial}
            >
              <ModalProvider>
                <ViewportProvider>
                  <App {...props} />
                </ViewportProvider>
              </ModalProvider>
            </AppProvider>
          )}
        />
      </Router>
    );
  }
}

fetch().then(render);
