import { motion } from "../../utilities/FramerMotion";
import useAppState from "../../providers/AppProvider";
import CondoRentalForm from "../forms/CondoRentalForm";
import styles from "../../../styles/partials/contact_us_popup/contact_us_formContianer.scss";
import { _classes } from "../../utilities/helpers";
const cl = _classes(styles);
CondosFormContainer.propTypes = {};

function CondosFormContainer() {
  const { activateCondo } = useAppState();

  return (
    <motion.div className={cl("_")}>
      {activateCondo && <CondoRentalForm />}
    </motion.div>
  );
}

export default CondosFormContainer;
