// import { useEffect } from "react";
import Link from "../../widgets/Link";
import useAppState from "../../providers/AppProvider";
import { _classes } from "../../utilities/helpers";
import styles from "../../../styles/partials/header/nav.scss";
import { motion } from "../../utilities/FramerMotion";
import useViewportContext from "../../providers/ViewportProvider";
import SubNav from "./SubNav";
import CTA from "../components/CTA";
const cl = _classes(styles);

Nav.propTypes = {};

function Nav() {
  const { viewport } = useViewportContext();
  const {
    opennav,
    setOpenNav,
    toggleActiveNavItem,
    setActiveNavItem,
    activeNavItem,
    toogleBookTour,
    toggleContactUs,
    childrenById,
  } = useAppState();

  const tablet = viewport.is("tablet");
  const items = childrenById(1).filter((item) => item.showinnav === true);
  const showsubnav = (item) =>
    tablet ? activeNavItem !== item.id : activeNavItem === item.id;
  const hasSub = (item) => !["/retail-spaces"].includes(item.path);
  const mobileNavCta = () => {
    if (tablet) {
      return (
        <>
          <li className={cl("cta_wrapper")}>
            <CTA text="BOOK A TOUR" color="white" onClick={toogleBookTour} />
          </li>
          <li className={cl("cta_wrapper")}>
            <CTA text="CONTACT" onClick={toggleContactUs} />
          </li>
        </>
      );
    } else return false;
  };
  // const NAV = tablet
  //   ? {
  //       animate: {
  //         x: !opennav ? window.innerWidth : 0,
  //         transition: { duration: 0.3, times: [0, 0.2, 1] },
  //       },
  //       initial: { x: 0 },
  //     }
  //   : {};
  // const closeNav = () => setOpenNav(false);
  const itemHover = (item) =>
    tablet
      ? {}
      : {
          onFocus: () => setActiveNavItem(item.id),
          onMouseEnter: () => toggleActiveNavItem(item.id),
          onMouseLeave: () => setActiveNavItem(null),
        };
  const mobileItemActive = (item) =>
    tablet
      ? {
          onClick: () => toggleActiveNavItem(item.id),
        }
      : {};

  return (
    <motion.nav
      className={cl(["_", tablet && !opennav && "hide"])}
      role="navigation"
    >
      {tablet && (
        <button
          className={cl("close_nav")}
          onClick={() => setOpenNav(!opennav)}
          aria-label="Close main menu"
        >
          <span className={cl(["close_text", "menu_text"])}>Menu</span>
          <span className={cl(["close_text", "close_text"])}>Close</span>
        </button>
      )}
      <div className={cl("container")}>
        <ul className={cl("items")}>
          {mobileNavCta()}
          {items &&
            items.map((item) => {
              const children = childrenById(item.id);
              const has_children = children.length > 0 && tablet;
              return (
                <li key={item.id} className={cl(["item"])} {...itemHover(item)}>
                  {item.template === "redirect" ? (
                    <Link
                      // path={item.h2 ? item.h2 : null}
                      className={cl(["link", has_children && "has_children"])}
                      {...mobileItemActive(item)}
                      ariaLabel={item.linktitle}
                    >
                      {item.linktitle}
                    </Link>
                  ) : (
                    <Link
                      path={tablet && hasSub(item) ? "" : item.path}
                      className={cl(["link", has_children && "has_children"])}
                      {...mobileItemActive(item)}
                      ariaLabel={item.linktitle}
                    >
                      {item.linktitle}
                    </Link>
                  )}
                  {showsubnav(item) && (
                    <SubNav items={children} parent={item} />
                  )}
                </li>
              );
            })}
        </ul>
      </div>
    </motion.nav>
  );
}

export default Nav;
