import React from "react";
import Modal from "../../widgets/Modal";
import { _classes } from "../../utilities/helpers";
import styles from "../../../styles/partials/availabilities/floorplan-modal.scss";
import Link from "../../widgets/Link";
import Image from "../../widgets/Image";
import downloadLogo from "../../../images/icons/download-icon.png";
const cl = _classes(styles);
import PropTypes from "prop-types";
FloorPlanModal.propTypes = {
  page: PropTypes.object,
  closeModal: PropTypes.func,
  open: PropTypes.bool,
};
export default function FloorPlanModal({ close, open, page }) {
  const renderModalContent = () => {
    if (page) {
      return (
        <div className={cl("modalContainer")}>
          {page.original ? (
            <Link
              className={cl("download_button_wrapper")}
              path={page.original}
              ariaLabel={`Download floor plan for this unit`}
            >
              <Image className={cl("download_button")} src={downloadLogo} />
            </Link>
          ) : null}
          <div
            className={cl("close_button")}
            onClick={close}
            aria-label="Exit floor plan modal for this unit"
          ></div>
          {page.original ? (
            <img className={cl("floor_plan")} src={page.original} />
          ) : (
            <div>No floorplan available for this unit</div>
          )}
        </div>
      );
    }
  };
  return (
    <Modal visible={open} close={close} className={cl("floorplan_modal")}>
      {renderModalContent()}
    </Modal>
  );
}
