import { _classes } from "../../utilities/helpers";
import styles from "../../../styles/partials/footer/footerBottom.scss";
import Image from "../../widgets/Image";
import rebnyFairHousing from "../../../images/rebny_logo_house.png";
import rebnyLogo from "../../../images/rebny_logo.png";
import Button from "../../widgets/Button";
import Link from "../../widgets/Link";
import Logo from "../header/Logo";
import fb from "../../../images/facebook.svg";
import ig from "../../../images/instagram.svg";
import linkedin from "../../../images/linkedin.svg";
import twitter from "../../../images/twitter.svg";
import { useAppState } from "../../providers";

const cl = _classes(styles);
import PropTypes from "prop-types";
FooterBottom.propTypes = {
  page: PropTypes.object,
};

function FooterBottom({ page }) {
  const { toogleBookTour, toggleContactUs } = useAppState();
  if (!page) return null;
  /////avoid this
  const icons = [
    {
      text: page.fieldgroup1[4].h1,
      url: JSON.parse(page.fieldgroup1[4].buttonlink1).link,
    },
    { image: fb, url: JSON.parse(page.fieldgroup1[0].buttonlink1).link },
    { image: twitter, url: JSON.parse(page.fieldgroup1[1].buttonlink1).link },
    { image: ig, url: JSON.parse(page.fieldgroup1[2].buttonlink1).link },
    { image: linkedin, url: JSON.parse(page.fieldgroup1[3].buttonlink1).link },
  ];
  const renderSocial = () => {
    if (page.fieldgroup1 && page.fieldgroup1.length > 0) {
      return icons.map((icon, index) => {
        return !icon.image && icon.url && icon.url !== "" ? (
          <Link
            key={index}
            className={cl("career")}
            title={icon.text}
            path={icon.url}
            ariaLabel="Navigate to careers page"
          />
        ) : (
          <Logo
            className={cl("social_icon")}
            key={index}
            path={icon.url}
            logo={icon.image}
            alt={icon.h1}
            ariaLabel={`Follow us on social media`}
          />
        );
      });
    }
  };
  const renderLinks = () => {
    if (page.fieldgroup2 && page.fieldgroup2.length > 0) {
      return page.fieldgroup2.map((element) => {
        return element.h1.toLowerCase() === "privacy policy" ||
          element.h1.toLowerCase() === "accessibility" ? (
          <Link
            key={element.id}
            className={cl("link")}
            title={element.h1}
            path={JSON.parse(element.buttonlink1).link}
          />
        ) : null;
      });
    }
  };

  const renderPDF = () => {
    return page.buttonblurb1 ? (
      <Link
        className={cl("link_pdf")}
        title={JSON.parse(page.buttonblurb1).text}
        path={JSON.parse(page.buttonblurb1).link}
      />
    ) : null;
  };

  const renderSticky = () => {
    if (page.fieldgroup2 && page.fieldgroup2.length > 0) {
      return page.fieldgroup2.map((element, index) => {
        if (element.h1.toLowerCase() === "book a tour") {
          return (
            <Button
              key={index}
              className={cl("sticky_button")}
              text={element.h1}
              onClick={() => {
                toogleBookTour();
              }}
            />
          );
        }
        if (element.h1.toLowerCase() === "contact us") {
          // need a onclick for contact popup
          return (
            <Button
              key={index}
              className={cl("sticky_button")}
              text={element.h1}
              onClick={toggleContactUs}
            />
          );
        }
      });
    }
  };
  return (
    <div className={cl("_")}>
      <div className={cl("rebny_logos")}>
        <div className={cl("logo_spacer")}>
          <Image
            className={cl("rebnyFairHousing")}
            src={rebnyFairHousing}
            alt="Rebny Fair Housing Logo"
          />
        </div>
        <Image
          className={cl("rebnyLogo")}
          src={rebnyLogo}
          alt="Rebny Real Estate Board of NY Logo"
        />
      </div>
      <div className={cl("social_handles")}>{renderSocial()}</div>

      <div className={cl("legal_links")}>
        <div className={cl("copy_right")}>{page.h1}</div>
        {renderLinks()}
        {renderPDF()}
        <div>
          <a
            className={styles.footer__credits}
            href="https://www.onceuponatime.agency/hospitadivty"
            data-txt="Credits"
            target="_blank"
            rel="noreferrer"
          >
            <span>Once Upon a Time</span>
          </a>
        </div>
      </div>
      <div className={cl("sticky")}>{renderSticky()}</div>
    </div>
  );
}

export default FooterBottom;
