// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html:not([class*=accessible]) .footer______sDF07{display:flex;flex-direction:column;justify-content:center;background-color:#2a292e;padding:4rem 2rem;width:100%}", "",{"version":3,"sources":["webpack://./src/styles/partials/footer.scss","webpack://./src/styles/global/_variables.scss"],"names":[],"mappings":"AAEE,iDACI,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,wBCHK,CDKL,iBAAA,CACA,UAAA","sourcesContent":["@import \"../global/parts\";\n@include _notAccessible {\n  ._ {\n      display: flex;\n      flex-direction: column;\n      justify-content: center;\n      background-color: $charcoal;\n      // align-items: center;\n      padding: 4rem 2rem;\n      width: 100%;\n      @include _minWidthTablet {\n        // height: 100%;\n        // padding: 4rem 2rem;\n      }\n  }\n}","// COLORS\n$white: #fff;\n$black: #000;\n$charcoal: #2a292e;\n$errorRed: #e01c1c;\n$blue: #4d90fe;\n$green: #096c18;\n$grey: #eeeeee;\n$darkGray: #cccccc;\n$gray: #777777;\n$gray_light: #aaa;\n$incentive_page_grey: #555;\n$light_grey: #e6e6ea;\n\n// BREAKPOINTS\n$xsmall: 400px;\n$small: 575px;\n$mobile: 768px;\n$tablet: 960px;\n$medium: 1200px;\n$large: 1400px;\n$xlarge: 1600px;\n\n// Gutter\n$mobileGutter: 25px;\n\n// TYPOGRAPHY\n$speed1: cubic-bezier(0.4, 1.13, 0.7, 0.95);\n\n// FONT SIZES\n$rootFontSize: 16px;\n\n$h1-size: 3.75rem; // 60px\n$h1-size-mobile: 2rem;\n\n$h2-size: 2.6rem;\n$h2-size-mobile: 1.8rem;\n\n$h3-size: 2rem;\n$h3-size-mobile: 1.6rem;\n\n$h4-size: 1.7rem;\n$h4-size-mobile: 1.4rem;\n\n$h5-size: 1.5rem;\n$h5-size-mobile: 1.3rem;\n\n$h6-size: 1.3rem;\n$h6-size-mobile: 1.2rem;\n\n$p-size: 0.9375rem;\n$p-size-mobile: 0.9375rem;\n\n$a-size: 0.9375rem;\n$a-size-mobile: 0.9375rem;\n\n$span-size: 0.9375rem;\n$span-size-mobile: 0.9375rem;\n\n// for icons\n$ease: ease;\n$duration: 400ms;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_": "footer______sDF07"
};
export default ___CSS_LOADER_EXPORT___;
