import styles from "../../../styles/partials/forms/commercialRental-form.scss";
import { _classes } from "../../utilities/helpers";
import HubspotContainer from "./HubspotContainer";
import useAppState from "../../providers/AppProvider";
const cl = _classes(styles);

import PropTypes from "prop-types";
CommercialRentalForm.propTypes = {
  className: PropTypes.string,
  formId: PropTypes.string.isRequired,
};

function CommercialRentalForm({ className, formId, phone }) {
  const { useRef_contactform } = useAppState();
  return (
    // This form is using Hubspot and formID is required
    <div className={cl(`hubspot_form_${className}`)} role="form">
      <HubspotContainer
        formId={formId}
        title="HUBSPOT FORM TEST"
        useRef_contactform={useRef_contactform}
      />

      <div tabIndex="0" className={cl("phone")}>
        {phone}
      </div>
    </div>
  );
}

export default CommercialRentalForm;
