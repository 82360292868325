import PropTypes from "prop-types";
Label.propTypes = {
  value: PropTypes.string,
  htmlFor: PropTypes.string,
};

export default function Label({ value, htmlFor }) {
  return value ? (
    <label htmlFor={htmlFor} className={"label"}>
      {value}
    </label>
  ) : null;
}
