import browser from "bowser";
import InvalidBrowser from "./utilities/InvalidBrowser/InvalidBrowser.js";
import Footer from "./partials/Footer";
import Header from "./partials/Header";
import APP_CONFIG from "./config/appConfig";
import Routes from "./containers/Routes.js";
import { useEffect } from "react";
import useAppState from "./providers/AppProvider.js";
import CookiePopup from "./partials/CookiePopup.js";
import PropTypes from "prop-types";
App.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
};

export default function App({ location }) {
  const {
    byPath,
    setOpenNav,
    setPath,
    setHostName,
    setBookTour,
    openContactUs,
    openBookTour,
    openApplyNow,
  } = useAppState();

  useEffect(() => {
    document.addEventListener("lazybeforeunveil", function (e) {
      var bg = e.target.getAttribute("data-bg");
      if (bg) {
        e.target.style.backgroundImage = "url(" + bg + ")";
      }
    });
    pageChangeRoutine();
    setTimeout(hideLoader, APP_CONFIG.loadingDuration);
    setHostName(window.location.hostname);
    if (location.pathname === "/schedule-tour") {
      setBookTour((old) => !old);
    }
  }, []);

  useEffect(() => {
    var hours = 0.5; // to clear the localStorage after half an hour
    // (if someone want to clear after 8hrs simply change hours=8)
    var now = new Date().getTime();
    var setupTime = localStorage.getItem("setupTime");
    if (setupTime == null) {
      localStorage.setItem("setupTime", now);
    } else {
      if (now - setupTime > hours * 60 * 60 * 1000) {
        localStorage.clear();
        localStorage.setItem("setupTime", now);
      }
    }
  }, []);

  useEffect(() => {
    trackPageView(location.pathname);
    pageChangeRoutine();
    setOpenNav(false);
    setPath(location.pathname);

    // const elemement = document.getElementById("navwarpper");
    // console.log("print the height", elemement.clientHeight);
    // setHeaderHeight(elemement.clientHeight);
    // console.log({ headerHeight });
  }, [location.pathname, location.search]);

  const hideLoader = () =>
    (document.querySelector(".site-loader").className += " loaded");

  const isValidBrowser = () =>
    !(browser.msie && parseInt(browser.version) < 11);

  const pageChangeRoutine = () => {
    const page = byPath(location.pathname);
    document.title = page.pagetitle || byPath("/").pagetitle;

    window.scrollTo(0, 0);
  };

  const trackPageView = (page) => {
    const { title } = document;
    if (window.ga) {
      // eslint-disable-next-line no-undef
      ga("send", "pageview", { page, title });
    }
  };

  if (!isValidBrowser()) {
    return <InvalidBrowser />;
  }

  return (
    // Inert it if any of the modals are open, so that the focus cannot go to background of modal
    <div inert={openContactUs || openBookTour || openApplyNow ? "" : false}>
      <Header />
      <Routes />
      <Footer />
      <CookiePopup />
    </div>
  );
}
