import styles from "../../../styles/partials/header/contact_us.scss";
import { _classes } from "../../utilities/helpers";
import Link from "../../widgets/Link";
const cl = _classes(styles);

import PropTypes from "prop-types";
ContactUs.propTypes = {
  tablet: PropTypes.bool,
};
function ContactUs() {
  return (
    <div className={cl("_")}>
      <Link
        className={cl("number")}
        title={"Contact us: 718-907-5800"}
        path={"tel:+1-718-907-5800"}
      ></Link>
    </div>
  );
}

export default ContactUs;
