import styles from "../../styles/widgets/_button.scss";

import PropTypes from "prop-types";
Button.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  ariaLabel: PropTypes.string.isRequired,
  key: PropTypes.string,
};

Button.defaultProps = {
  text: "",
  onClick: () => null,
  className: "",
  tabIndex: 0,
};

export default function Button({
  text,
  onClick,
  className,
  tabIndex,
  ariaLabel,
  key,
}) {
  return (
    <button
      className={`${styles.button} ${className}`}
      onClick={onClick}
      aria-label={ariaLabel ? ariaLabel : text}
      tabIndex={tabIndex}
      key={key}
    >
      {text}
    </button>
  );
}
