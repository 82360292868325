import styles from "../../../styles/partials/header/headerMessage.scss";
import { _classes } from "../../utilities/helpers";
import Link from "../../widgets/Link";

const cl = _classes(styles);
HeaderMessage.propTypes = {};

function HeaderMessage({ message, selectedBg, path }) {
  return (
    <div className={cl(`_${selectedBg}`)} role="banner">
      <Link title={message} className={cl(`${selectedBg}`)} path={path} />
    </div>
  );
}

export default HeaderMessage;
