import useAppState from "../providers/AppProvider";
import FooterLogo from "./footer/FooterLogo";
import FooterBuildings from "./footer/FooterBuildings.js";
import FooterBottom from "./footer/FooterBottom";
import { _classes } from "../utilities/helpers";
import styles from "../../styles/partials/footer.scss";
const cl = _classes(styles);
Footer.propTypes = {};
function Footer() {
  const { byTemplate } = useAppState();
  const page = byTemplate("footer")[0];
  const residential =
    byTemplate("neighborhood").filter(
      (element) => element.path === "/apartments/"
    ) || [];
  const commercial =
    byTemplate("neighborhood").filter(
      (element) => element.path !== "/apartments/"
    ) || [];
  return (
    <footer className={cl("_")}>
      <FooterLogo page={page} />
      <FooterBuildings
        className={cl("residential")}
        type={"residential"}
        buildings={residential}
        page={page}
        title={page?.h4}
      />
      <FooterBuildings
        className={cl("commercial")}
        type={"commerical"}
        buildings={commercial}
        page={page}
        title={page?.h5}
      />
      <FooterBottom page={page} />
    </footer>
  );
}

export default Footer;
