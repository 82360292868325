import ErrorMessage from "./ErrorMessage";
import Label from "./Label";
import { Controller } from "react-hook-form/dist/index.ie11";
import Select from "react-select";
import { useFormContext } from "./FormProvider";
import { v4 as uuidv4 } from "uuid";
const UUID = uuidv4();

import PropTypes from "prop-types";
SelectInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  rules: PropTypes.object,
  options: PropTypes.array,
  className: PropTypes.string,
  onSelect: PropTypes.func,
  controlClass: PropTypes.string,
  selectClass: PropTypes.string,
  isSearchable: PropTypes.bool,
};

SelectInput.defaultProps = {
  className: "",
  backgroundColor: "",
  onSelect: false,
  isSearchable: false,
};

export default function SelectInput({
  name,
  placeholder,
  label,
  options = [],
  rules,
  className,
  onSelect,
  controlClass,
  selectClass,
  isSearchable,
}) {
  // const [ modal, setOpenModal ] = useState(false);
  const { control, errors } = useFormContext();
  const id = `${name}__${UUID}`;

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ onChange, value }) => {
        return (
          <div
            className={"field field-select " + className + " " + selectClass}
          >
            <Label value={label} htmlFor={id} tabIndex="0" />
            <div className={"control " + controlClass}>
              <Select
                inputProps={{ id }}
                name={name}
                label={label}
                placeholder={placeholder}
                value={options?.find((rec) => rec.value === value)}
                onChange={(selected) => {
                  onChange(selected ? selected.value : null);
                  onSelect && onSelect(selected);
                }}
                options={options}
                classNamePrefix={"select"}
                className={"select " + selectClass}
                isSearchable={isSearchable}
              />
            </div>
            <ErrorMessage error={errors[name]} />
          </div>
        );
      }}
    />
  );
}
