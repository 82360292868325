import { createContext, useContext, useState, useEffect } from "react";
import { buildNestioUrl, parse } from "../utilities/helpers";
import moment from "moment";
import React from "react";

const AppContext = createContext({});

import PropTypes from "prop-types";
AppProvider.propTypes = {
  children: PropTypes.node,
  content: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
};

export function AppProvider({
  children,
  content,
  history,
  location,
  residential,
  nestio,
}) {
  const [accessible, setAccessible] = useState(false);
  const [animating, setAnimating] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(158);
  const [opennav, setOpenNav] = useState(false);
  const [openBookTour, setBookTour] = useState(false);
  const [openContactUs, setContactUs] = useState(false);
  const [openApplyNow, setApplyNow] = useState(false);
  const [activateResidential, setActivateResidential] = useState(false);
  const [activateCommercial, setActivateCommercial] = useState(false);
  const [activateCondo, setActivateCondo] = useState(false); //Note: Hate this naming, just keeping it the same as is here.
  const [activeNavItem, setActiveNavItem] = useState(null);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [availabilitiesFilters, setAvailabilitiesFilter] = useState(
    parse(localStorage.getItem("availabilities"), [])
  );
  const [openFilters, setOpenFilters] = useState([]);
  const [filtersByBuilding, setFiltersByBuildings] = useState(
    parse(localStorage.getItem("buildingIds"), [])
  );
  const [filterByLayout, setFilterByLayout] = useState(
    parse(localStorage.getItem("layouts"), [])
  );
  const [filterByPrice, setFilterByPrice] = useState({ min: -1, max: -1 });
  const [filterBySqft, setFilterBySqft] = useState({ min: -1, max: -1 });
  const [incentiveSuccess, setIncentiveSuccess] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [propertyType, setPropertyType] = useState("residential");
  const [applyBuilding, setApplyBuilding] = useState("/");
  const [mobileFilter, setMobileFilter] = useState(false);
  const [floorPlan, setFloorPlan] = useState(null);
  const [buildingFilters, setBuildingFilters] = useState([]); // not sure if this is ever used
  const [path, setPath] = useState(location.pathname);
  const [hostName, setHostName] = useState(location.hostname);
  const [open, setOpen] = useState(false);
  const [useRef_contactform, setRef_contactform] = useState();
  const [useRef_scheduletourform, setRef_scheduletourform] = useState();

  const toggle = () => {
    setOpen(false);
    setTimeout(() => {
      setOpen((s) => !s);
    }, 500);
  };

  const _sortEvents = (a, b) =>
    moment(a.start_date).toDate() - moment(b.start_date).toDate();

  if (!content) {
    return null;
  }

  const byId = (id) => content[id] || {};

  const byPath = (path) => {
    path = path.toLowerCase();
    path = path.slice(-1) === "/" && path.length > 1 ? path.slice(0, -1) : path;

    const id = Object.keys(content).find((key) => content[key].path === path);
    return content[id] || {};
  };

  const byTemplate = (template) =>
    Object.keys(content)
      .filter((key) => content[key].template === template)
      .map((key) => content[key])
      .sort((a, b) => a.sortorder - b.sortorder);

  const childrenById = (id) =>
    Object.keys(content)
      .filter((key) => content[key].parentid === id)
      .map((key) => content[key])
      .sort((a, b) => a.sortorder - b.sortorder);

  const childrenByPath = (path) => childrenById(byPath(path).id);

  const parentById = (id) => byId(byId(id).parentid);

  const parentByPath = (path) => byId(byPath(path).parentid);

  const allEvents = () => {
    const events = byTemplate("calendar_detail");

    return events
      .reduce((sum, event) => {
        const eventType = JSON.parse(event.calendartype).type;

        if (eventType === "recurring" && event.parentid === event.calendarid) {
          const children = childrenById(event.id).sort(_sortEvents);

          const child =
            children.find(
              (e) => moment(e.start_date).toDate() >= moment().toDate()
            ) || event;

          if (moment(event.start_date).toDate() >= moment().toDate()) {
            //if original event is still in future, push OG event
            sum.push(event);
          } else {
            //otherwise push most next upcoming child
            sum.push({
              ...event,
              id: child.id,
              parentid: child.parentid,
              start_date: child.start_date,
              end_date: child.end_date,
            });
          }
        }

        if (eventType !== "recurring") {
          sum.push(event);
        }

        return sum;
      }, [])
      .sort(_sortEvents);
  };

  const activeEvents = () => {
    return allEvents()
      .filter((event) => {
        const today = new Date();
        const calendarType = JSON.parse(event.calendartype);

        return (
          moment(event.end_date).toDate().getTime() >= today.getTime() ||
          (calendarType.type === "consecutive" &&
            moment(event.end_date).toDate().getTime() >= today.getTime())
        );
      })
      .sort(_sortEvents);
  };

  const toggleAccessible = (e) => {
    e.preventDefault();
    setAccessible(!accessible);
    document.querySelector("html").classList.toggle("accessible");
  };

  const toggleActiveNavItem = (id) => {
    if (activeNavItem === id) {
      setActiveNavItem(null);
    } else {
      setActiveNavItem(id);
    }
  };
  const toggleOpenFilter = (id) =>
    setOpenFilters((state) =>
      state.includes(id) ? state.filter((i) => i !== id) : [...state, id]
    );

  const toggleFilter = (id, shouldDeselectOthers = false) => {
    // console.log("ToggleFilter", id);

    setAvailabilitiesFilter((state) => {
      if (shouldDeselectOthers) {
        setFiltersByBuildings(() => childrenById(id).map((b) => b.id));
        return [id];
      }
      // unchecking the filter checkbox
      if (state.includes(id)) {
        setFiltersByBuildings((buildingIds) => {
          const neighborhoodChildren = childrenById(id).map((b) => b.id);
          return buildingIds.filter(
            (item) => !neighborhoodChildren.includes(item || id)
          );
        });
        return state.filter((i) => i !== id);
      } else {
        // checking the filter checkbox
        setFiltersByBuildings((state) => {
          const neighborhoodChildren = childrenById(id).map((b) => b.id);
          return [...state, ...neighborhoodChildren];
        });
        return [...state, id];
      }
    });
  };
  const toggleFilterArray = (ids, shouldDeselectOthers = false) => {
    setAvailabilitiesFilter((prevState) => {
      let newState = [...prevState];
      let newBuildingIds = [...filtersByBuilding];

      ids.forEach((id) => {
        if (shouldDeselectOthers) {
          newBuildingIds = childrenById(id).map((b) => b.id);
          newState = [id];
        } else {
          if (prevState.includes(id)) {
            newBuildingIds = newBuildingIds.filter(
              (item) =>
                !childrenById(id)
                  .map((b) => b.id)
                  .includes(item)
            );
            newState = newState.filter((i) => i !== id);
          } else {
            newBuildingIds = [
              ...newBuildingIds,
              ...childrenById(id).map((b) => b.id),
            ];
            newState = [...newState, id];
          }
        }
      });

      setFiltersByBuildings(newBuildingIds);
      return newState;
    });
  };
  const trackParent = (filtersByBuilding, availabilitiesFilters) => {
    const findParentArray = filtersByBuilding.map(
      (element) => parentById(element).id
    );
    const removeDuplicates = [...new Set(findParentArray)];
    const result = removeDuplicates
      .filter((value) => availabilitiesFilters.includes(value))
      .sort();
    return result;
  };

  ///////// This is to track when to remove the parent neighborhood toggle after the last building in the neighborhood got de-selected   ///////////
  const array1 = trackParent(filtersByBuilding, availabilitiesFilters);
  const array2 = Array.isArray(availabilitiesFilters)
    ? availabilitiesFilters.sort()
    : [];
  const is_same =
    array1.length == array2.length &&
    array1.every(function (element, index) {
      return element === array2[index];
    });
  useEffect(() => {
    if (!is_same) {
      setAvailabilitiesFilter((state) => {
        return state.filter((item) => array1.includes(item));
      });
    }
  }, [filtersByBuilding]);
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const toggleFilterByBuilding = (id, parentid) => {
    setFiltersByBuildings((state) => {
      if (state.includes(id)) {
        return state.filter((i) => i !== id);
      } else {
        if (!availabilitiesFilters.includes(parentid)) {
          setAvailabilitiesFilter((state) => [...state, parentid]);
        }
        return [...state, id].flat();
      }
    });
  };

  const toggleByLayout = (layout) =>
    setFilterByLayout((state) =>
      state.includes(layout)
        ? state.filter((str) => str !== layout)
        : [...state, layout]
    );

  const toggleByPrice = (price, type) =>
    setFilterByPrice((state) => ({ ...state, [type]: price }));
  const toggleBySqft = (sqft, type) =>
    setFilterBySqft((state) => ({ ...state, [type]: sqft }));
  const toogleBookTour = () => {
    setContactUs(false);
    setBookTour((old) => !old);
    setApplyNow(false);
    setOpenNav(false);
  };
  const closeBookTour = () => setBookTour(false);
  const toggleContactUs = () => {
    setContactUs((old) => !old);
    setBookTour(false);
    setApplyNow(false);
    setOpenNav(false);
  };
  const closeContactUs = () => setContactUs(false);
  const toggleApplyNow = () => setApplyNow((old) => !old);
  const closeApplyNow = () => setApplyNow(false);
  const toggleResidentailForms = () => setActivateResidential((old) => !old);
  const toggleCommercialForms = () => setActivateCommercial((old) => !old);
  const toggleCondoForms = () => setActivateCondo((old) => !old);
  const resetAllFilters = () => {
    setAvailabilitiesFilter([]);
    setFiltersByBuildings([]);
    setFilterByLayout([]);
    setFilterByPrice({ min: -1, max: -1 });
    setFilterBySqft({ min: -1, max: -1 });
  };

  const allNestData = nestio?.reduce((sum, acc) => sum.concat(acc), []);

  const getNestioDataByPath = (path= window.location.pathname)=>{
    const allnestio = nestio
    ? nestio.flatMap((a) => a.items)
    : [];
    const processedNestio = allnestio.map((page) => {
      const path =
        page?.property_type === "Residential"
          ? buildNestioUrl(page, "apartments")
          : buildNestioUrl(page, "office-spaces");

      return { ...page, path };
    });
    const currentPage = processedNestio.find(
      (page) => page.path === path
    );

    return currentPage || {}
  }

  return (
    <AppContext.Provider
      value={{
        content,
        history,
        location,
        byId,
        byPath,
        byTemplate,
        childrenById,
        childrenByPath,
        parentById,
        parentByPath,
        allEvents,
        activeEvents,
        accessible,
        toggleAccessible,
        animating,
        setAnimating,
        opennav,
        setOpenNav,
        activeNavItem,
        setActiveNavItem,
        toggleActiveNavItem,
        neighborhoods,
        setNeighborhoods,
        residential,
        nestio,
        availabilitiesFilters,
        toggleFilter,
        toggleFilterArray,
        openFilters,
        toggleOpenFilter,
        filtersByBuilding,
        toggleFilterByBuilding,
        filterByLayout,
        toggleByLayout,
        filterByPrice,
        toggleByPrice,
        incentiveSuccess,
        setIncentiveSuccess,
        resetForm,
        setResetForm,
        openBookTour,
        setBookTour,
        toogleBookTour,
        closeBookTour,
        resetAllFilters,
        openContactUs,
        setContactUs,
        toggleContactUs,
        closeContactUs,
        activateResidential,
        setActivateResidential,
        activateCommercial,
        setActivateCommercial,
        toggleResidentailForms,
        toggleCommercialForms,
        activateCondo,
        setActivateCondo,
        toggleCondoForms,
        openApplyNow,
        setApplyNow,
        toggleApplyNow,
        closeApplyNow,
        applyBuilding,
        setApplyBuilding,
        propertyType,
        setPropertyType,
        mobileFilter,
        setMobileFilter,
        floorPlan,
        setFloorPlan,
        filterBySqft,
        setFilterBySqft,
        setFilterByLayout,
        toggleBySqft,
        buildingFilters,
        setBuildingFilters,
        allNestData,
        path,
        setPath,
        hostName,
        setHostName,
        toggle,
        open,
        setOpen,
        setAvailabilitiesFilter,
        useRef_contactform,
        setRef_contactform,
        useRef_scheduletourform,
        setRef_scheduletourform,
        headerHeight,
        setHeaderHeight,
        setFiltersByBuildings,
        getNestioDataByPath
      }}
    >
      <React.Fragment>{children}</React.Fragment>
    </AppContext.Provider>
  );
}

export default function useAppState() {
  return useContext(AppContext);
}
