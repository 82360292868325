import React from "react";
import { createPortal } from "react-dom";
import useAppState from "../../providers/AppProvider";
import { _classes, parse } from "../../utilities/helpers";
import styles from "../../../styles/partials/contact_us_popup/contact_us_popup.scss";
import { motion } from "../../utilities/FramerMotion";
import ResidentialFormContainer from "./ResidentialFormContainer";
import CommercialFormContainer from "./CommercialFormContainer";
import CondosFormContainer from "./CondosFormContainer";
import Link from "../../widgets/Link";
import { useState, useEffect, useRef } from "react";
const cl = _classes(styles);

import PropTypes from "prop-types";
ContactUsPopUp.propTypes = {
  contactInfo: PropTypes.object,
};

function ContactUsPopUp() {
  const containerTop = useRef();
  const {
    openContactUs,
    closeContactUs,
    toggleResidentailForms,
    toggleCommercialForms,
    toggleCondoForms,
    byTemplate,
    setRef_contactform,
  } = useAppState();
  const [activateButtonResidential, setButtonResidential] = useState(false);
  const [activateButtonCommercial, setButtonCommercial] = useState(false);
  const [activateButtonCondo, setButtonCondo] = useState(false);
  const contactInfo = byTemplate("global")[0] || {};
  const variants = {
    animate: {
      x: !openContactUs ? 755 : 0,
      transition: { duration: 0.3, times: [0, 0.2, 1] },
    },
    initial: { x: 755 },
  };

  const toggleButton = (active, setActivate) => {
    if (active === false) {
      setActivate(!active);
    } else {
      setActivate(false);
    }
  };
  const terms =
    [
      ...new Set(
        contactInfo.fieldgroup3.map(
          (element) => parse(element.selectgroup1)[0].name
        )
      ),
    ] || [];

  const renderContactNumber = (index) => {
    if (contactInfo && contactInfo.fieldgroup3.length > 0) {
      return (
        <div>
          <div
            className={cl("type_title")}
            tabIndex="0"
            aria-label={`Contact information for ${terms[index]}`}
          >
            {terms[index]}
          </div>
          <div className={cl("contact_container")}>
            {contactInfo.fieldgroup3.map((contact, idx) => {
              if (
                terms.find(
                  () => parse(contact.selectgroup1)[0].name === terms[index]
                )
              ) {
                return (
                  <div key={idx} className={cl("contact_details")}>
                    <div className={cl("contact_office")} tabIndex="0">
                      {contact.h2.toUpperCase()}
                    </div>
                    <Link
                      className={cl("contact_number")}
                      title={contact.h1}
                      path={`tel:+1-${contact.h1}`}
                    />
                  </div>
                );
              }
            })}
          </div>
        </div>
      );
    }
  };

  const iterateListOfContact = () => {
    return terms.map((term, index) => {
      return renderContactNumber(index);
    });
  };
  let scrollerElement;
  useEffect(() => {
    setTimeout(
      () => (scrollerElement = document.getElementById("scroller")),
      2000
    );
    setTimeout(() => setRef_contactform(scrollerElement), 2500);
  }, [scrollerElement]);

  const portalRoot = document.querySelector(".contactUsModalPortal");
  const el = document.createElement("div");

  useEffect(() => {
    if (portalRoot && el) portalRoot.appendChild(el);
    return () => portalRoot.removeChild(el);
  }, []);

  React.useEffect(() => {
    const activeElement = document.activeElement;
    document.addEventListener("keydown", handleKeydown);
    return () => {
      // Detach listener when component unmounts
      document.removeEventListener("keydown", handleKeydown);
      // Return focus to the previously focused element
      activeElement.focus();
    };
  }, []);

  // Map key codes to their respective event handlers
  const keyListenersMap = new Map([
    // Hint: keycode 13 is the enter key
    [13, handleKeydown],
  ]);

  const handleKeydown = (evt) => {
    // get the listener corresponding to the pressed key
    const listener = keyListenersMap.get(evt.keyCode);

    // call the listener if it exists
    return listener && listener(evt);
  };

  return createPortal(
    <motion.div
      className={cl("_")}
      {...variants}
      id="scroller"
      ref={containerTop}
      aria-modal="true"
      role="alertdialog"
    >
      <div className={cl(["background", openContactUs && "active"])}></div>
      <div className={cl("form_container")}>
        <div className={cl("scrollWrapper")}>
          <div className={cl("popup_wrapper")}>
            <div className={cl("title_wrapper")}>
              <div className={cl("title")} tabIndex="0">
                Contact Us
              </div>
              <div className={cl("close_button_wrapper")}>
                <div
                  className={cl("close_button")}
                  onClick={closeContactUs}
                  aria-label="Exit contact form"
                  tabIndex="0"
                  onKeyUp={(e) => {
                    e.key === "Enter" && closeContactUs();
                  }}
                />
              </div>
            </div>
            <div className={cl("form_toggle_container")}>
              <div
                className={cl("button_commerical")}
                aria-selected={activateButtonCommercial}
                role="button"
                onClick={() => {
                  toggleCommercialForms();
                  toggleButton(activateButtonCommercial, setButtonCommercial);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    toggleCommercialForms();
                    toggleButton(activateButtonCommercial, setButtonCommercial);
                  }
                }}
                tabIndex="0"
              >
                <div
                  className={cl("form_toggle")}
                  tabIndex="0"
                  aria-label="commercial leasing selection is focused, press space to open the menu"
                >
                  Commercial Leasing
                </div>
                <div
                  className={cl([
                    "icon",
                    "plus-to-minus",
                    activateButtonCommercial && "minus",
                  ])}
                ></div>
              </div>
              <CommercialFormContainer />
              <div
                tabIndex="0"
                role="button"
                className={cl("button_residential")}
                onClick={() => {
                  toggleResidentailForms();
                  toggleButton(activateButtonResidential, setButtonResidential);
                }}
                aria-selected={activateButtonResidential}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    toggleResidentailForms();
                    toggleButton(
                      activateButtonResidential,
                      setButtonResidential
                    );
                  }
                }}
              >
                <div
                  className={cl("form_toggle")}
                  tabIndex="0"
                  aria-label="apartments selection is focused, press space to open the menu"
                >
                  Apartment Rentals
                </div>
                <div
                  className={cl([
                    "icon",
                    "plus-to-minus",
                    activateButtonResidential && "minus",
                  ])}
                ></div>
              </div>
              <ResidentialFormContainer />

              <div
                className={cl("button_commerical")}
                aria-selected={activateButtonCondo}
                role="button"
                onClick={() => {
                  toggleCondoForms();
                  toggleButton(activateButtonCondo, setButtonCondo);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    toggleCondoForms();
                    toggleButton(activateButtonCondo, setButtonCondo);
                  }
                }}
                tabIndex="0"
              >
                <div
                  className={cl("form_toggle")}
                  tabIndex="0"
                  aria-label="condominium sales selection is focused, press space to open the menu"
                >
                  Condominium Sales
                </div>
                <div
                  className={cl([
                    "icon",
                    "plus-to-minus",
                    activateButtonCondo && "minus",
                  ])}
                ></div>
              </div>
              <CondosFormContainer />
            </div>

            <div className={cl("contactInfoSection")}>
              {iterateListOfContact()}
              {contactInfo && contactInfo.address1 && contactInfo.address2 && (
                <div className={cl("address_container")}>
                  <div className={cl("address")}>{contactInfo.address1}</div>
                  <div className={cl("address")}>{contactInfo.address2}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </motion.div>,
    document.querySelector(".contactUsModalPortal")
  );
}

export default ContactUsPopUp;
