import { motion } from "../../utilities/FramerMotion";
import useAppState from "../../providers/AppProvider";
import CommercialRentalForm from "../forms/CommercialRentalForm";
import styles from "../../../styles/partials/contact_us_popup/contact_us_formContianer.scss";
import { _classes } from "../../utilities/helpers";
const cl = _classes(styles);
CommercialFormContainer.propTypes = {};

function CommercialFormContainer() {
  const { activateCommercial } = useAppState();
  return (
    <motion.div className={cl("_")}>
      {activateCommercial && (
        <CommercialRentalForm
          className={"global"}
          formId={"e248423d-c930-438f-8a64-ce1c36a4dac9"}
        />
      )}
    </motion.div>
  );
}

export default CommercialFormContainer;
