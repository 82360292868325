import { useEffect, useState } from "react";
import { LazyBoundary } from "react-imported-component";
import { Switch, Route, Redirect } from "react-router-dom";
import useAppState from "../providers/AppProvider";
import ErrorBoundary from "../utilities/ErrorBoundary";
import { buildNestioUrl } from "../utilities/helpers";
import { AnimatePresence, motion } from "../utilities/FramerMotion";
import viewsPaths from "../utilities/viewPaths";
import FloorPlanModal from "../partials/availability/FloorPlanModal";

Routes.propTypes = {};

export default function Routes() {
  const {
    content,
    location,
    nestio,
    floorPlan,
    setFloorPlan,
    byId,
    headerHeight,
  } = useAppState();
  const [routes, setRoutes] = useState([]);
  const home = byId(1);

  useEffect(() => {
    const baseRoutes = createBaseRoutes();
    setRoutes(baseRoutes);
  }, []);

  const allnestio =
    nestio &&
    nestio.map((a) => a.items).reduce((sum, acc) => sum.concat(acc), []);
  // const allnestio = nestio

  const residentialRoutes = () => {
    const Component = viewsPaths[`apartment`] || viewsPaths["default"];

    return (
      allnestio &&
      allnestio.length > 0 &&
      allnestio?.map((page) => {
        const path =
          page?.property_type === "Residential"
            ? buildNestioUrl(page, "apartments")
            : buildNestioUrl(page, "office-spaces");
        // console.log("Page in Route", page);

        return (
          <Route
            exact
            key={page?.id}
            path={path}
            render={(props) => (
              <LazyBoundary fallback={<div />}>
                <ErrorBoundary>
                  <Component page={page} {...props} nestioData={allnestio} />
                </ErrorBoundary>
              </LazyBoundary>
            )}
          />
        );
      })
    );
  };

  const createBaseRoutes = () => {
    const donotroute = ["footer", "header", "virtual_tour", "view_photos"];
    const setPage = (page) => page;

    return Object.keys(content)
      .map((key) => {
        const page = content[key];
        const template = page?.template?.toLowerCase();
        const Component =
          viewsPaths[`${template === "schedule_tour" ? "home" : template}`] ||
          viewsPaths["default"];
        const formattedPage = setPage(page);
        return (
          !donotroute.includes(page.template) && (
            <Route
              exact
              key={formattedPage.id}
              path={formattedPage.path}
              render={(props) => (
                <LazyBoundary fallback={<div />}>
                  <ErrorBoundary>
                    <Component
                      page={
                        formattedPage.path === "/schedule-tour"
                          ? home
                          : formattedPage
                      }
                      {...props}
                      nestioData={allnestio}
                    />
                  </ErrorBoundary>
                </LazyBoundary>
              )}
            />
          )
        );
      })
      .concat(residentialRoutes())
      .concat(
        <Route
          path="/"
          key="redirectAllElse"
          render={() => <Redirect to="/" />}
        />
      );
  };

  return (
    <main style={{ marginTop: headerHeight }} id="main">
      <AnimatePresence exitBeforeEnter>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ ease: "easeInOut", duration: 0.75, delay: 0 }}
          className="page"
          key={location.key}
        >
          <Switch location={location}>{routes}</Switch>
        </motion.div>
      </AnimatePresence>
      <FloorPlanModal
        open={floorPlan ? true : false}
        close={() => setFloorPlan(false)}
        page={floorPlan}
      />
    </main>
  );
}
