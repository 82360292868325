import React, { useRef } from "react";
import { createPortal } from "react-dom";
import useAppState from "../../providers/AppProvider";
import { _classes } from "../../utilities/helpers";
import styles from "../../../styles/partials/schedule_tour/schedule_tour.scss";
import { motion } from "../../utilities/FramerMotion";
import ScheduleTourForm from "../forms/ScheduleTourForm";
import { useEffect } from "react";
const cl = _classes(styles);

function ScheduleTour() {
  const containerTop = useRef();
  const { openBookTour, closeBookTour, setRef_scheduletourform } =
    useAppState();
  const variants = {
    animate: {
      x: !openBookTour ? 600 : 0,
      transition: { duration: 0.3, times: [0, 0.2, 1] },
    },
    initial: { x: 600 },
  };

  let scrollerElement;
  useEffect(() => {
    setTimeout(
      () => (scrollerElement = document.getElementById("scroller")),
      2000
    );
    setTimeout(() => setRef_scheduletourform(scrollerElement), 2500);
  }, [scrollerElement]);

  const portalRoot = document.querySelector(".bookATourModal");
  const el = document.createElement("div");

  useEffect(() => {
    if (portalRoot && el) portalRoot.appendChild(el);
    return () => portalRoot.removeChild(el);
  }, []);

  React.useEffect(() => {
    const activeElement = document.activeElement;
    document.addEventListener("keydown", handleKeydown);
    return () => {
      // Detach listener when component unmounts
      document.removeEventListener("keydown", handleKeydown);
      // Return focus to the previously focused element
      activeElement.focus();
    };
  }, []);

  // Map key codes to their respective event handlers
  const keyListenersMap = new Map([
    // Hint: keycode 13 is the enter key
    [13, handleKeydown],
  ]);

  const handleKeydown = (evt) => {
    // get the listener corresponding to the pressed key
    const listener = keyListenersMap.get(evt.keyCode);

    // call the listener if it exists
    return listener && listener(evt);
  };

  return createPortal(
    <motion.div
      className={cl("_")}
      {...variants}
      id="scroller"
      ref={containerTop}
      aria-modal="true"
      role="alertdialog"
    >
      <div className={cl(["background"])}></div>
      <div className={cl("form_container")}>
        <div className={cl("scrollWrapper")}>
          <div className={cl("title_wrapper")}>
            <div className={cl("title")} tabIndex="0">
              Schedule a Tour
            </div>
            <div className={cl("close_button_wrapper")}>
              <div
                className={cl("close_button")}
                onClick={closeBookTour}
                onKeyUp={(e) => {
                  e.key === "Enter" && closeBookTour();
                }}
                tabIndex="0"
              />
            </div>
          </div>
          <ScheduleTourForm />
        </div>
      </div>
    </motion.div>,
    document.querySelector(".bookATourModal")
  );
}

ScheduleTour.propTypes = {};

export default ScheduleTour;
