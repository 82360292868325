export const calendarArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
  </svg>
);
export const circlePlus = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1000 1000"
    enableBackground="new 0 0 1000 1000"
  >
    <g>
      <g>
        <path d="M500,10C229.4,10,10,229.4,10,500c0,270.6,219.4,490,490,490c270.6,0,490-219.4,490-490C990,229.4,770.6,10,500,10z M779.6,578.7H571.1v208.5c0,35.7-28.9,64.7-64.7,64.7c-35.7,0-64.7-29-64.7-64.7V578.7H233.4c-35.7,0-64.7-28.9-64.7-64.7c0-35.7,29-64.7,64.7-64.7h208.5V240.9c0-35.7,29-64.7,64.7-64.7c35.7,0,64.7,28.9,64.7,64.7v208.5h208.5c35.7,0,64.7,29,64.7,64.7C844.3,549.8,815.3,578.7,779.6,578.7z" />
      </g>
    </g>
  </svg>
);

export const leftArrow = (
  <svg
    width="17px"
    height="13px"
    viewBox="0 0 17 13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(-646.000000, -1065.000000)">
      <g transform="translate(40.000000, 731.000000)" fill="#096C18">
        <g transform="translate(588.000000, 320.000000)">
          <polygon
            id="Page-1"
            points="35 19.5295634 21.6086812 19.5295634 25.6928625 15.3587417 24.3626429 14 18 20.5 24.3626429 27 25.6928625 25.6412583 21.6086812 21.4704366 35 21.4704366"
          ></polygon>
        </g>
      </g>
    </g>
  </svg>
);

export const smallClearIcon = (
  <svg width="11px" height="11px">
    <path
      fillRule="evenodd"
      fill="rgb(0, 0, 0)"
      d="M9.577,10.848 L0.348,1.619 L0.860,1.107 L10.089,10.335 L9.577,10.848 Z"
    />
    <path
      fillRule="evenodd"
      fill="rgb(0, 0, 0)"
      d="M0.348,10.079 L9.577,0.850 L10.089,1.363 L0.860,10.592 L0.348,10.079 Z"
    />
  </svg>
);

export const bigClearIcon = (
  <svg width="34px" height="35px">
    <path
      fillRule="evenodd"
      fill="rgb(0, 0, 0)"
      d="M31.757,34.890 L0.434,3.567 L2.174,1.827 L33.497,33.150 L31.757,34.890 Z"
    />
    <path
      fillRule="evenodd"
      fill="rgb(0, 0, 0)"
      d="M0.434,32.280 L31.757,0.957 L33.497,2.697 L2.174,34.020 L0.434,32.280 Z"
    />
  </svg>
);
export const externalLink = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="none"
    viewBox="0 0 24 24"
  >
    <g>
      <path
        id="Vector"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10 5H8.2c-1.12 0-1.68 0-2.108.218a1.999 1.999 0 0 0-.874.874C5 6.52 5 7.08 5 8.2v7.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874c.427.218.987.218 2.105.218h7.606c1.118 0 1.677 0 2.104-.218.377-.192.683-.498.875-.874.218-.428.218-.987.218-2.105V14m1-5V4m0 0h-5m5 0-7 7"
      />
    </g>
  </svg>
);
