import Link from "../../widgets/Link";
import Image from "../../widgets/Image";
import PropTypes from "prop-types";
Logo.propTypes = {
  className: PropTypes.string,
  logo: PropTypes.string,
  path: PropTypes.string,
  alt: PropTypes.string,
  ariaLabel: PropTypes.string.isRequired,
};
export default function Logo({ className, logo, path, alt, ariaLabel }) {
  // const path = window.location.pathname !== "/" ? "/" : "/";
  return (
    <div className={className}>
      <Link path={path} ariaLabel={ariaLabel}>
        <Image src={logo} alt={alt || ""} type="element" />
      </Link>
    </div>
  );
}
