import { useState, useEffect } from "react";
import Nav from "./header/Nav";
import HeaderLogoSection from "./header/HeaderLogoSection";
import HeaderMessage from "./header/HeaderMessage";
import ContactUs from "./header/ContactUs";
import useViewportContext from "../providers/ViewportProvider";
import useAppState from "../providers/AppProvider";
import { _classes, parse } from "../utilities/helpers";
import { Waypoint } from "react-waypoint";
import styles from "../../styles/partials/header.scss";
import ScheduleTour from "./SidePopUps/ScheduleTour";
import ContactUsPopUp from "./SidePopUps/ContactUsPopUp";
import ApplyNow from "./SidePopUps/ApplyNow";
import SkipNav from "../widgets/SkipNav";

const cl = _classes(styles);

Header.propTypes = {};
function Header() {
  const [header, setHeader] = useState();

  const { viewport } = useViewportContext();
  const tablet = viewport.is("tablet");
  const onEnter = () => {
    setHeader(false);
  };
  const onLeave = () => {
    setHeader(true);
  };

  const {
    byTemplate,
    byId,
    setHeaderHeight,
    openContactUs,
    openBookTour,
    openApplyNow,
  } = useAppState();

  const headerMessage = byTemplate("global")[0] || {};
  const homepage_message =
    (headerMessage && headerMessage.fieldgroup6 && headerMessage.fieldgroup6) ||
    [];

  const [order, setOrder] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      cycleMessage();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const cycleMessage = () => {
    const messageLength =
      homepage_message.length > 0 && homepage_message.length - 1;
    setOrder((state) => (state === messageLength ? 0 : state + 1));
  };
  const backgroundColor =
    (homepage_message &&
      homepage_message.length > 0 &&
      parse(homepage_message[order].selectgroup1)) ||
    [];
  const selectedBg =
    backgroundColor && backgroundColor.length
      ? backgroundColor[0].name
      : "green";

  const onLoad = () => {
    const elemement = document.getElementById("navwarpper");
    setHeaderHeight(() =>
      header ? elemement.clientHeight + 36 : elemement.clientHeight
    );
  };
  useEffect(() => {
    onLoad();
  }, [window.location.pathname, window.innerWidth]);

  const render = () => {
    if (
      !window.location.pathname.includes("/office-spaces") &&
      !window.location.pathname.includes("/apartments") &&
      !window.location.pathname.includes("/about-us/art-and-community") &&
      !window.location.pathname.includes("/neighborhood-school-grants") &&
      homepage_message.length > 0
    ) {
      return (
        <HeaderMessage
          selectedBg={selectedBg}
          message={homepage_message[order].h1}
          path={byId(homepage_message[order].h3).path}
        />
      );
    }

    if (
      window.location.pathname.includes("/office-spaces") &&
      headerMessage.h4 !== ""
    ) {
      return (
        <HeaderMessage
          selectedBg={"green"}
          message={headerMessage.h4}
          path={byId(headerMessage.h3).path}
        />
      );
    }
    if (
      window.location.pathname.includes("/apartments") &&
      headerMessage.h5 !== ""
    ) {
      return (
        <HeaderMessage
          selectedBg={"green"}
          message={headerMessage.h5}
          path={byId(headerMessage.h6).path}
        />
      );
    }
  };
  return (
    <header className={cl(["_", header && !tablet && "resize"])}>
      <SkipNav link={"#main"} />
      <div id="navwarpper" className={cl(["wrapper"])}>
        {render()}
        <ContactUs tablet={tablet} />
        <HeaderLogoSection resize={header} />
        {openApplyNow && <ApplyNow />}
        <div className={cl("navWrapper")}>
          <Nav />
        </div>
        {openBookTour && <ScheduleTour />}
        {openContactUs && <ContactUsPopUp />}
      </div>
      <Waypoint onEnter={onEnter} topOffset="-150px" onLeave={onLeave} />
    </header>
  );
}

export default Header;
